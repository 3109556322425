import "./HeadingItem.scss";
import { useMemo } from "react";
import { SortButton } from "@mercdev.com/mercurial-ui";
import cn from "classnames";

import { ILink } from "/@types";
import { useLinkStore } from "stores/LinkListStore";

import { linkPropertyToSortBy } from "../../properties";
import { useEffect, useState } from "react";

import { getOrderString, setStringToOrder } from "utils/getOrderString";
import { SortDirection } from "api/links";
interface IProps {
  className?: string;
  property: keyof ILink;
  title: string;
}

export const HeadingItem = ({ className, property, title }: IProps) => {
  const [orderBy, setOrderBy] = useLinkStore((state) => [
    state.orderBy,
    state.setOrder,
  ]);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  useEffect(() => {
    if (orderBy) {
      setSortBy(Object.keys(setStringToOrder(orderBy))[0]);
      setSortDirection(Object.values(setStringToOrder(orderBy))[0]);
    }
  }, [orderBy]);

  const onSortHandle = (property: keyof ILink) => {
    setOrderBy(
      getOrderString([
        {
          key: linkPropertyToSortBy[property],
          value:
            sortDirection === SortDirection.asc
              ? SortDirection.desc
              : SortDirection.asc,
        },
      ])
    );
  };

  return (
    <li className={cn(className, "heading-item")}>
      <div className="heading-item-inner">
        <SortButton
          label={title}
          isActive={sortBy === linkPropertyToSortBy[property]}
          onClick={() => onSortHandle(property)}
          // @ts-ignore
          direction={sortDirection}
        />
      </div>
    </li>
  );
};
