import "./ShortenerHeader.scss";

import { logOut, useUser } from "@mercdev.com/auth";
import {
  ActionButton,
  Button,
  createToast,
  Header,
  IconCancel,
  IconDoorOut,
  IconLink,
  IconSearch,
  Menu,
  TextInput,
  Tooltip,
} from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import normalizeUrl from "normalize-url";
import {
  FocusEvent,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";

import { useFolders } from "utils/FoldersContext";
import {
  addFavoriteLink,
  addLinkToFolder,
  checkLinkExist,
  createLink,
  useLinkList,
} from "api/links";
import {
  CONSTANTS,
  DEFAULT_FOLDERS,
  LINK_MESSAGES,
  HTTP_REGEX,
  URL_REGEX,
} from "const";
import { useLinkStore } from "stores/LinkListStore";
import { mutateLinksData } from "utils/mutateLinksData";
import { services } from "utils/generateServiceList";

interface IProps {
  inputUrlRef: MutableRefObject<HTMLInputElement | null>;
  setInputUrlFocus: () => void;
}

type InputMode = "search" | "shorten" | "unspecified" | null;

const ShortenerHeader = ({ inputUrlRef, setInputUrlFocus }: IProps) => {
  const [inputValue, setInputValue] = useState("");
  const [isShorteningLoading, setIsShorteningLoading] = useState(false);
  const [inputMode, setInputMode] = useState<InputMode>(null);
  const [isClearMode, setIsClearMode] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(true);
  const [hasExistingLinksChecked, setHasExistingLinksChecked] = useState(false);

  const [folder, setFolder] = useLinkStore((state) => [
    state.folder,
    state.setFolder,
  ]);
  const [orderBy] = useLinkStore((state) => [state.orderBy]);
  const [search, setSearch] = useLinkStore((state) => [
    state.search,
    state.setSearch,
  ]);
  const [page, setPage] = useLinkStore((state) => [state.page, state.setPage]);
  const [size] = useLinkStore((state) => [state.size]);
  const [isNewLink, setIsNewLink] = useLinkStore((state) => [
    state.isNewLink,
    state.setIsNewLink,
  ]);
  const [isExists, setIsExists] = useLinkStore((state) => [
    state.isExists,
    state.setIsExists,
  ]);

  const { user } = useUser();
  const { folders, isFoldersLoading, isFoldersError, mutateFolders } =
    useFolders();
  const { isLinksLoading, isLinksError, mutateLinks } = useLinkList(
    folder?.id || "",
    orderBy,
    search,
    page,
    size
  );

  const isFavoritesFolder = useMemo(() => {
    return folder?.name === DEFAULT_FOLDERS.FAVORITES;
  }, [folder]);

  useEffect(() => {
    (async () => {
      if (!inputValue) {
        if (inputMode === "search" || inputMode === "unspecified") {
          setSearch("");
        }

        setInputMode(null);
        setIsShorteningLoading(false);
        setIsClearMode(false);

        return;
      }
      if (HTTP_REGEX.test(inputValue)) {
        setInputMode("shorten");
        const normalizedUrl = normalizeUrl(inputValue, { forceHttps: true });
        const { data } = await checkLinkExist(normalizedUrl);
        setHasExistingLinksChecked(data);
        if (data) {
          setSearch(inputValue.replace(/www\./, ""));
          setIsExists(true);
        }
      } else {
        if (URL_REGEX.test(inputValue)) {
          setInputMode("unspecified");
          setIsClearMode(true);
        } else setInputMode("search");
      }
    })();
  }, [inputMode, inputValue, setSearch]);

  useEffect(() => {
    if (inputMode === "shorten") {
      inputUrlRef?.current?.focus();
    }
  }, [isLinksLoading, isFoldersLoading]);

  const onClear = () => {
    setInputValue("");
    setIsNewLink("");
    setIsExists(false);
    if (hasExistingLinksChecked) setSearch("");
    setHasExistingLinksChecked(false);
  };

  const onSearch = () => {
    if (inputUrlRef.current) {
      inputUrlRef.current.focus();
    }
    setSearch(inputValue);
    setIsClearMode(true);
  };

  const onCreateLink = async () => {
    if (!folder) return;
    setIsExists(false);
    const normalizedUrl = normalizeUrl(inputValue, { forceHttps: true });
    try {
      const { data } = await createLink(normalizedUrl);
      setIsNewLink(data.id);

      if (isFavoritesFolder) await addFavoriteLink(data.id);
      else if (
        folder.name !== DEFAULT_FOLDERS.MINE &&
        folder.name !== DEFAULT_FOLDERS.ALL
      )
        await addLinkToFolder(data.id, folder.id);

      await mutateLinks((oldLinks) => {
        return mutateLinksData(oldLinks, "add", data);
      }, false);
      await mutateFolders(
        async (oldFolders) =>
          (oldFolders || []).map((of) => ({
            ...of,
            amount: of.id === folder?.id ? of.amount + 1 : of.amount,
          })),
        false
      );

      setHasExistingLinksChecked(false);
      setInputValue("");
      setInputMode("unspecified");
      setPage(1);
    } catch (err) {
      createToast({
        text: LINK_MESSAGES.CREATED_FAILED,
        color: "negative",
      });
      if (inputUrlRef.current) {
        inputUrlRef.current.focus();
      }
      setIsShorteningLoading(false);
    }
  };

  const onShorten = async () => {
    setIsShorteningLoading(true);

    const normalizedUrl = normalizeUrl(inputValue, { forceHttps: true });
    try {
      if (!hasExistingLinksChecked && inputMode !== "unspecified") {
        const isExistingLinks = await checkLinkExist(normalizedUrl);

        if (isExistingLinks.data) {
          if (folders) {
            const folder = folders.find((f) => f.name === DEFAULT_FOLDERS.ALL);
            if (folder) setFolder(folder);
          }
          setSearch(normalizedUrl);

          setHasExistingLinksChecked(true);
          setIsShorteningLoading(false);
          setInputUrlFocus();
        } else {
          await onCreateLink();
        }
      } else {
        setSearch("");
        /* Fixes the issue with links data cache on All tab not updated
         * if a user had been creating an existing link on another tab */

        await onCreateLink();
      }
    } catch (e) {
      const backendError = e.response.data[0];

      if (backendError.code === "BadlyFormattedUrl") {
        createToast({
          text: backendError.description,
          color: "negative",
        });
      } else {
        createToast({
          text: CONSTANTS.GENERAL_ERROR,
          color: "negative",
        });
      }

      setIsShorteningLoading(false);
    }
  };

  const onBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
    setIsInputFocused(false);
    if (inputMode) setIsClearMode(true);
  };

  const onFocus = () => {
    setIsInputFocused(true);
    if (inputMode !== "unspecified") setIsClearMode(false);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = async (e) => {
    if (inputMode === "unspecified") {
      if (e.key === "Enter") {
        if (e.shiftKey) await onShorten();
        else onSearch();
      }
    } else if (e.key === "Enter") {
      inputMode === "search" && onSearch();
      inputMode === "shorten" && !isExists && (await onShorten());
      inputMode === "shorten" && isExists && (await onCreateLink());
    }
  };

  const onChange = (value: string) => {
    if (hasExistingLinksChecked) {
      setSearch("");
      setHasExistingLinksChecked(false);
    }

    setInputValue(value);
  };

  return (
    <Header className="header" isFixed={false}>
      <Header.Logo
        iconName="shortener"
        title="Shortener"
        description="By Mercury Development"
        env={import.meta.env.VITE_MODE}
      />

      <Header.Content className="header-input">
        <TextInput
          autoFocus
          ref={inputUrlRef}
          placeholder="Insert a long URL or type a search request and press Enter"
          value={inputValue}
          isLoading={isShorteningLoading}
          isDisabled={
            (isLinksLoading && inputValue !== "") ||
            isLinksError ||
            !user ||
            isFoldersLoading ||
            isFoldersError
          }
          actionButton={
            isClearMode ? (
              <ActionButton
                isSquared
                size="small"
                title="Clear"
                color="light"
                iconName={IconCancel}
                onClick={onClear}
              />
            ) : (
              inputMode &&
              {
                search: (
                  <Button
                    className="input-action"
                    size="small"
                    iconName={IconSearch}
                    variant="secondary"
                    onClick={onSearch}
                    onMouseDown={onSearch}
                  >
                    Search
                  </Button>
                ),
                shorten: (
                  <>
                    <Button
                      id="button-shorten"
                      className="input-action"
                      size="small"
                      iconName={IconLink}
                      variant="secondary"
                      onClick={onShorten}
                      onMouseDown={onShorten}
                    >
                      Shorten
                    </Button>

                    {hasExistingLinksChecked && (
                      <Tooltip
                        id="button-shorten"
                        theme="dark"
                        isOpen={hasExistingLinksChecked}
                      >
                        The link already exists. <br />
                        Press again to shorten anyway.
                      </Tooltip>
                    )}
                  </>
                ),
                unspecified: <></>,
              }[inputMode]
            )
          }
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />

        <div
          className={cn("header__input-dropdown input-dropdown", {
            "input-dropdown_shown":
              inputMode === "unspecified" && isInputFocused,
          })}
        >
          <Menu direction="vertical" isDropdown={true}>
            <Menu.Item
              icon={IconSearch}
              description="Enter"
              onMouseDown={onSearch}
            >
              Search existing short links
            </Menu.Item>

            <Menu.Item
              icon={IconLink}
              description="Shift + Enter"
              onMouseDown={onShorten}
            >
              Create a new short link
            </Menu.Item>
          </Menu>
        </div>
      </Header.Content>

      {user && (
        <Header.Aside
          activeService="shortener"
          profile={{
            name: user.name.first,
            avatar: user.avatar,
          }}
          dropdown={{
            offset: [0, 8],
            withArrow: true,
            arrowPosition: "right",
            menu: (
              <Menu direction="vertical" isDropdown={true}>
                <Menu.Item icon={IconDoorOut} onClick={() => logOut()}>
                  Log out
                </Menu.Item>
              </Menu>
            ),
          }}
          serviceList={services(user)}
        ></Header.Aside>
      )}

      {!user && (
        <div className="profile-skeleton">
          <Skeleton className="header-profile__name" height={20} width={125} />
          <Skeleton height={46} width={46} />
        </div>
      )}
    </Header>
  );
};

export default ShortenerHeader;
