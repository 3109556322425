export const SHORTENER_API_URL = import.meta.env.VITE_SHORTENER_API_URL;

export const CONSTANTS = {
  MIN_DESKTOP_WIDTH: 1280,
  DATE_FORMAT: "dd.MM.yyyy",
  GENERAL_ERROR: "Something went wrong",
};

export const LINK_MESSAGES = {
  CREATED_COPIED:
    "Your short link has been successfully added and copied into a clipboard.",
  CREATED_FAILED:
    "Link was not created. Try again or check your internet connection.",
  CHANGED_COPIED:
    "Your short link has been successfully changed and copied into a clipboard.",
  DELETED: "The link has been deleted.",
  COPIED: "The link has been successfully copied into your clipboard.",
  COPIED_ERROR: "An error occurred while copying the link, please try again.",
  EXISTS_ERROR: "Already exists",
  REMOVAL_REQUEST_ERROR:
    "The link removal request has not been sent. Please try again.",
  ADDED_TO_FOLDERS: "The link has been successfully added to the folders.",
  DROPPED_TO_FOLDER_ERROR:
    "Something went wrong. The link has not been added to the folder. Please, try again.",
};

export const FAVORITES_MESSAGES = {
  ADDED: "The link has been added to the favorites.",
  REMOVED: "The link has been removed from favorites.",
};

export const EXPIRATION_DATE_MESSAGES = {
  ADDED: "Expiration date has been added.",
  ADDED_ERROR:
    "An error occurred while adding expiration date, please try again.",
  CHANGED: "Expiration date changes have been saved.",
  CHANGED_ERROR:
    "An error occurred while saving expiration date, please try again.",
};

export const DEFAULT_FOLDERS = {
  MINE: "My Links",
  FAVORITES: "Favorites",
  ALL: "All Links",
};

export const defaultFoldersSet = new Set([
  DEFAULT_FOLDERS.ALL,
  DEFAULT_FOLDERS.MINE,
  DEFAULT_FOLDERS.FAVORITES,
]);

export const PAGINATION_MENU_ITEMS = [
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

export const HTTP_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const URL_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
