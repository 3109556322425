import React, { useState } from "react";
import {
  Paragraph,
  Heading,
  Modal,
  ModalCalendar,
  createToast,
} from "@mercdev.com/mercurial-ui";
import { EXPIRATION_DATE_MESSAGES } from "const";
import { putLinkExpirationDate, useLinkList } from "api/links";
import { useLinkStore } from "stores/LinkListStore";
import { mutateLinksData } from "utils/mutateLinksData";

const ExpirationCalendar = ({ showModalCalendar, link, handleHideModal }) => {
  const [isLoading, setLoading] = useState(false);

  const [folder] = useLinkStore((state) => [state.folder]);
  const [orderBy] = useLinkStore((state) => [state.orderBy]);
  const [search] = useLinkStore((state) => [state.search, state.setSearch]);
  const [page] = useLinkStore((state) => [state.page]);
  const [size] = useLinkStore((state) => [state.size]);

  const { mutateLinks } = useLinkList(
    folder?.id || "",
    orderBy,
    search,
    page,
    size
  );

  const onAddExpirationDate = async (date) => {
    setLoading(true);

    try {
      const hasExpirationDate = link.expirationDate;
      await putLinkExpirationDate(link.id, date);

      setLoading(false);
      handleHideModal();

      await mutateLinks((oldLinks) => {
        return mutateLinksData(
          oldLinks,
          "edit",
          date,
          link.id,
          "expirationDate"
        );
      }, false);

      createToast({
        text: hasExpirationDate
          ? EXPIRATION_DATE_MESSAGES.CHANGED
          : EXPIRATION_DATE_MESSAGES.ADDED,
      });
    } catch (e) {
      setLoading(false);

      createToast({
        text: link.expirationDate
          ? EXPIRATION_DATE_MESSAGES.CHANGED_ERROR
          : EXPIRATION_DATE_MESSAGES.ADDED_ERROR,
        color: "negative",
      });
    }
  };

  return (
    <ModalCalendar
      show={showModalCalendar}
      onHide={handleHideModal}
      isDisabledSave={isLoading}
      isLoadingSave={isLoading}
      initialValue={link.expirationDate ? new Date(link.expirationDate) : null}
      onSubmit={(date) => {
        onAddExpirationDate(date);
      }}
    >
      <Modal.Title className="">
        <Heading headLevel={3} size={3}>
          Expiration Date
        </Heading>
      </Modal.Title>
      <Modal.Text>
        <Paragraph size={3}>
          Select the day when the project will be removed.
        </Paragraph>
      </Modal.Text>
    </ModalCalendar>
  );
};

export default ExpirationCalendar;
