export const mutateLinksData = (data, mode, newValue, id, key) => {
  switch (mode) {
    case "edit": {
      const objectIndex = data.data.findIndex((item) => item.id === id);

      if (objectIndex !== -1) {
        data.data[objectIndex][key] = newValue;
      } else {
        console.warn(`Object with id ${id} not found.`);
      }
      break;
    }

    case "delete": {
      const objectIndex = data.data.findIndex((item) => item.id === id);

      if (objectIndex !== -1) {
        data.data.splice(objectIndex, 1);
      } else {
        console.warn(`Object with id ${id} not found.`);
      }
      break;
    }

    case "add": {
      data.data.unshift(newValue);
      break;
    }

    default:
      console.warn(`Unknown mode: ${mode}`);
  }
  return data;
};
