import { create } from "zustand";

import { IFolder } from "../api/folders";
import { PAGINATION_MENU_ITEMS } from "const";
import { orderByDefault } from "api/links";

interface LinkListStore {
  folder: IFolder | undefined;
  orderBy: string;
  search: string;
  page: number;
  size: number;
  isNewLink?: string;
  isExists?: boolean;

  setFolder: (v: IFolder) => void;
  setOrder: (v: string) => void;
  setSearch: (v: string) => void;
  setPage: (v: number) => void;
  setPageSize: (v: number) => void;
  setIsNewLink: (v: string) => void;
  setIsExists: (v: boolean) => void;
}

export const useLinkStore = create<LinkListStore>((set) => ({
  folder: undefined,
  orderBy: orderByDefault,
  search: "",
  page: 1,
  size: PAGINATION_MENU_ITEMS[0].value,
  isNewLink: "",
  isExists: false,

  setFolder: (v) => set({ folder: v }),
  setOrder: (v) => set({ orderBy: v }),
  setSearch: (v) => set({ search: v }),
  setPage: (v) => set({ page: v }),
  setPageSize: (v) => set({ size: v }),
  setIsNewLink: (v) => set({ isNewLink: v }),
  setIsExists: (v) => set({ isExists: v }),
}));
