import { ILink } from "/@types";
import { SortByValues } from "api/links";

export const linkPropertyToSortBy: Partial<Record<keyof ILink, SortByValues>> =
  {
    url: "longUrl",
    owner: "owner",
    createdAt: "date",
    views: "views",
    shortUrl: "shortUrl",
  };
