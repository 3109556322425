import "./Loader.scss";

import cn from "classnames";
import { FC } from "react";

const Loader: FC<{
  className?: string;
  size: "small" | "normal" | "large";
  isLight?: boolean;
  isInContainer?: boolean;
}> = ({ className, size, isLight, isInContainer }) => {
  return isInContainer ? (
    <div className="loader-container">
      <div
        className={cn(className, "loader", `loader_size_${size}`, {
          loader_light: isLight,
        })}
      />
    </div>
  ) : (
    <div
      className={cn(className, "loader", `loader_size_${size}`, {
        loader_light: isLight,
      })}
    />
  );
};
export default Loader;
