import "./TextEditor.scss";

import { Tooltip } from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  MutableRefObject,
} from "react";

interface IProps {
  className?: string;
  value: string;
  isDisabled: boolean;
  error?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onBlur: () => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  ref?: MutableRefObject<HTMLInputElement | null>;
}

interface TextEditorRef {
  focusInput(): void;
}

const TextEditor = forwardRef<TextEditorRef, IProps>(
  (
    {
      className,
      value,
      error,
      isDisabled,
      onChange,
      onSubmit,
      onBlur,
      onFocus,
    },
    ref
  ) => {
    const spanRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (!!spanRef?.current?.innerText && inputRef.current) {
        const spanWidth = spanRef.current.offsetWidth;
        inputRef.current.style.width = `${spanWidth}px`;
      }
    }, [spanRef]);

    useImperativeHandle(ref, () => ({
      focusInput() {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
    }));

    useEffect(() => {
      inputRef.current && inputRef.current.focus();
      spanRef.current.innerText = inputRef.current.value;
    }, [inputRef]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      spanRef.current.innerText = e.target.value;
      onChange(e);
    };

    return (
      <form
        className={cn(className, "text-editor", {
          "text-editor_error": error,
        })}
        onSubmit={onSubmit}
      >
        <input
          autoFocus
          ref={inputRef}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={isDisabled}
          onFocus={onFocus}
          aria-label="edit url"
        />
        <span
          id="text-editor"
          ref={spanRef}
          style={{
            position: "absolute",
            left: 0,
            visibility: "hidden",
            whiteSpace: "pre",
          }}
        />

        {error && (
          <Tooltip id="text-editor" isOpen={true} theme="negative" place="top">
            {error}
          </Tooltip>
        )}
      </form>
    );
  }
);

export default TextEditor;
