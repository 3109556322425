import { FC } from "react";

import Skeleton from "react-loading-skeleton";
import "./SkeletonPage.scss";

import SkeletonContent from "./SkeletonContent";

const SkeletonPage: FC = () => {
  return (
    <div className="app">
      <div className="document__app">
        <div className="link-list__item">
          <Skeleton height={56} borderRadius={12} />
        </div>
        <div className="link-list__header">
          <ul className="link-list-heading">
            <li className="long-url-column table-header">
              <Skeleton height={32} />
            </li>
            <li className="owner-column table-header">
              <Skeleton height={32} />
            </li>
            <div className="date-added-column table-header">
              <Skeleton height={32} />
            </div>
            <li className="views-column table-header">
              <Skeleton height={32} />
            </li>
            <li className="short-link-column table-header">
              <Skeleton height={32} />
            </li>
          </ul>
          <SkeletonContent />
        </div>
      </div>
    </div>
  );
};

export default SkeletonPage;
