import "./style.scss";

import { useMemo } from "react";
import { useDragLayer, XYCoord } from "react-dnd";

import Favicon from "../../../Link/components/Favicon/Favicon";
import { SHORTENER_API_URL } from "const";

function getItemStyles(cursorOffset: XYCoord | null) {
  if (!cursorOffset)
    return {
      display: "none",
    };

  const { x, y } = cursorOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

const CustomDragLayer = () => {
  const visibleLinkDomain = useMemo(
    () => SHORTENER_API_URL.replace("http://", "").replace("https://", ""),
    [SHORTENER_API_URL]
  );

  const { isDragging, item, cursorOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    cursorOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div className="layer">
      <div style={getItemStyles(cursorOffset)}>
        <div className="preview">
          <Favicon
            className="preview__favicon"
            src={`${SHORTENER_API_URL}/links/favicon/${item.id}`}
          />

          {`${visibleLinkDomain}/${item.shortUrl}`}
        </div>
      </div>
    </div>
  );
};

export default CustomDragLayer;
