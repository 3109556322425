import "./style.scss";

import {
  ActionButton,
  IconCheckmark,
  IconDrag,
  IconEdit,
  IconTrash,
  Loader,
} from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import { FC, HTMLAttributes, LegacyRef, useState } from "react";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";

import { IFolder, updateFolderName } from "../../api/folders";
import { useFolders } from "utils/FoldersContext";
import FolderDeleteModal from "../../modals/FolderDeleteModal/FolderDeleteModal";
import wrapIntoDefaultErrorHandler from "../../utils/wrapIntoDefaultErrorHandler";
import FolderAction from "../FolderAction/FolderAction";

type Props = {
  folder: IFolder;
  isLoading?: boolean;
  isDragging?: boolean;
  isChecked?: boolean;
  dndRef?: LegacyRef<HTMLDivElement>;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  onCheck?: () => void;
  hideFoldersManagementModal?: (v: boolean) => void;
} & HTMLAttributes<HTMLDivElement>;

const Folder: FC<Props> = ({
  className,
  folder,
  isLoading,
  isDragging,
  isChecked,
  dndRef,
  draggableProps,
  dragHandleProps,
  onCheck,
  hideFoldersManagementModal,
  ...props
}) => {
  const [isRenamingMode, setRenamingMode] = useState(false);
  const [isDeleteFolderModalShown, setDeleteFolderModalShown] = useState(false);

  const { mutateFolders } = useFolders();

  const renameFolder = async (newName: string) => {
    await wrapIntoDefaultErrorHandler(async () => {
      const { data: newFolders } = await updateFolderName(folder.id, newName);

      setRenamingMode(false);
      await mutateFolders(newFolders);
    });
  };

  return (
    <>
      {!isRenamingMode ? (
        <div
          className={cn(className, "folder", {
            folder_checked: isChecked,
            folder_loading: isLoading,
            folder_dragging: isDragging,
          })}
          ref={dndRef}
          onClick={onCheck}
          {...draggableProps}
          {...props}
        >
          <div className="folder-info">
            {dndRef && (
              <div className="drag-icon-wrapper" {...dragHandleProps}>
                <IconDrag className="drag-icon" size={20} />
              </div>
            )}

            <span className="folder-info__name">{folder.name}</span>
          </div>

          {onCheck ? (
            <div className="folder__checkbox checkbox">
              <IconCheckmark className="checkbox__checkmark" size={16} />
            </div>
          ) : (
            <div className="folder__actions folder-actions">
              <ActionButton
                isSquared
                iconName={IconEdit}
                size="small"
                onClick={() => setRenamingMode(true)}
                variant="secondary"
              />

              <ActionButton
                isSquared
                iconName={IconTrash}
                size="small"
                color="negative"
                onClick={() => setDeleteFolderModalShown(true)}
                variant="secondary"
              />
            </div>
          )}

          <Loader className="folder__loader" color="accent" size={24} />
        </div>
      ) : (
        <FolderAction
          className={className}
          value={folder.name}
          onSubmit={renameFolder}
          onCancel={() => setRenamingMode(false)}
        />
      )}

      <FolderDeleteModal
        show={isDeleteFolderModalShown}
        folder={folder}
        onHide={() => setDeleteFolderModalShown(false)}
        hideFoldersManagementModal={hideFoldersManagementModal}
      />
    </>
  );
};

export default Folder;
