import "./style.scss";

import { Tabs } from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import { FC } from "react";
import { useDrop } from "react-dnd";

import { ILink } from "@types";
import Loader from "components/Loader/Loader";

type Props = {
  text: string;
  counter?: number;
  isLoading?: boolean;
  onLinkDrop: (item: ILink) => void;
};

const DroppableTabItem: FC<Props> = ({
  text,
  counter,
  isLoading,
  onLinkDrop,
}) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "link",
      drop: onLinkDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <div
      ref={drop}
      className={cn("droppable-tab-item", {
        "droppable-tab-item_active": isOver,
      })}
    >
      <p>{text}</p>
      {isLoading ? (
        <Loader className="droppable-tab-item__loader" size="small" />
      ) : (
        <Tabs.Counter value={counter} />
      )}
    </div>
  );
};

export default DroppableTabItem;
