import { useUser } from "@mercdev.com/auth";
import { createToast } from "@mercdev.com/mercurial-ui";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { SHORTENER_API_URL } from "const";

interface IHubConnectionResponse {
  from: string;
  linkId: string;
  message: string;
}

const useSignalR = () => {
  const [connection, setConnection] = useState<HubConnection | null>(null);

  const { user } = useUser();

  useEffect(() => {
    if (!user?.email) return;

    if (!SHORTENER_API_URL)
      throw new Error("Please, set up VITE_SHORTENER_API_URL first");

    const connection = new HubConnectionBuilder()
      .withUrl(
        `${SHORTENER_API_URL}/hubs/linkdeletionrequest?userEmail=` + user?.email
      )
      .withAutomaticReconnect()
      .build();

    setConnection(connection);
  }, [user?.email]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on(
            "LinkDeletionRequestNotificationMessage",
            (hubConnectionResponse: IHubConnectionResponse) => {
              createToast({
                text: hubConnectionResponse.message,
                color: "light",
              });
            }
          );

          connection.on(
            "DeletedLinkNotificationMessage",
            (hubConnectionResponse: IHubConnectionResponse) => {
              createToast({
                text: hubConnectionResponse.message,
                color: "light",
              });
            }
          );
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);
};

export default useSignalR;
