import React, { createContext, useContext, ReactNode, useMemo } from "react";
import useSWR from "swr";
import { fetcher } from "api/fetcher";

export interface IFolder {
  id: string;
  name: string;
  orderNumber: number;
  amount: number;
}

const FoldersContext = createContext(undefined);

export const FoldersProvider = ({ children }: { children: ReactNode }) => {
  const { data, error, mutate } = useSWR<IFolder[]>(`/folders/list`, fetcher, {
    refreshInterval: 0,
  });

  const value = useMemo(
    () => ({
      folders: data,
      isFoldersLoading: !data && !error,
      isFoldersError: !!error,
      mutateFolders: mutate,
    }),
    [data, error, mutate]
  );

  return (
    <FoldersContext.Provider value={value}>{children}</FoldersContext.Provider>
  );
};

export const useFolders = () => {
  const context = useContext(FoldersContext);
  if (context === undefined) {
    throw new Error("useFolders must be used within a FoldersProvider");
  }
  return context;
};
