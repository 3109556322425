import clsx from "clsx";
import React, {
  Children,
  cloneElement,
  FC,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dropdown, Button, Menu, IconMore } from "@mercdev.com/mercurial-ui";

export type TabsProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type TabsAsideProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export type TabsCounterProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export type TabsItemProps = {
  id?: string;
  isActive?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  isDisabled?: boolean;
  variant?: "ghost" | "solid";
  color?: "primary";
  /**
   * Отображать разделитель слева?
   */
  withDivider?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>;

export type TabsListPropsBaseProps = {
  /**
   * Указываем активный индекс, по умолчанию 0.
   */
  activeIndex?: number;
  /**
   * Callback на изменение активного таба. Возвращает индекс активного таба.
   * @param v
   */
  onChange?: (v: number) => void;
  size?: "normal" | "medium" | "small";
  portalId?: string;
};

export type TabsListProps = TabsListPropsBaseProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  >;

export const Tabs: FC<TabsProps> = ({ children, className }) => {
  const [withAside, setWithAside] = useState(false);
  const [size, setSize] = useState<"normal" | "medium" | "small">("normal");

  useEffect(() => {
    Children.map(children, (child) => {
      const item = child as ReactElement<PropsWithChildren<TabsListProps>>;

      if (item) {
        setSize(item.props.size || "normal");

        if (item.type === TabsAside) {
          setWithAside(true);
        }
      }
    });
  }, [children]);

  return (
    <div
      className={clsx([className, "mk-ui-tabs", `mk-ui-tabs_size_${size}`], {
        "mk-ui-tabs_with-aside": withAside,
      })}
    >
      {children}
    </div>
  );
};

export const TabsList: FC<TabsListProps> = ({
  children,
  className,
  size = "normal",
  activeIndex = 0,
  onChange,
  portalId,
  ...props
}) => {
  const listRef = useRef<HTMLUListElement>(null);

  const [activeTab, setActiveTab] = useState<number>();
  const [hasOverflow, setHasOverflow] = useState(false);
  const [visibilityMap, setVisibilityMap] = useState<boolean[]>([]);
  const [itemsLength, setItemsLength] = useState(0);

  const isDropdownActive = useMemo(() => {
    if (visibilityMap.length !== itemsLength) {
      return false;
    }

    if (typeof activeTab === "number") {
      return !visibilityMap[activeTab];
    }
    return !visibilityMap[activeIndex];
  }, [activeIndex, activeTab, itemsLength, visibilityMap]);

  useEffect(() => {
    setItemsLength(
      Array.from(listRef.current?.children || []).filter(
        (item) => (item as HTMLElement).dataset.observable
      ).length
    );

    const updateMenu = () => {
      const tabsWidth = document.getElementById("tab-list")?.offsetWidth - 52;
      let totalWidth = 0;
      let overflow = false;

      // @ts-ignore
      const entries = children.map((item, index) => {
        const element = listRef.current.children[index] as HTMLElement;
        const itemWidth = element.offsetWidth;
        const isVisible = totalWidth + itemWidth < tabsWidth;
        if (isVisible) {
          totalWidth += itemWidth + 4;
        } else {
          totalWidth = tabsWidth;
          overflow = true;
        }
        return { ...item, isVisible };
      });
      setHasOverflow(overflow);
      setVisibilityMap(entries.map((entry) => entry.isVisible));
    };

    updateMenu();
    window.addEventListener("resize", updateMenu);
    return () => window.removeEventListener("resize", updateMenu);
  }, [listRef.current, children]);

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const onClickTabHandle = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ updatedItem, idx }: { updatedItem: any; idx: number }) => {
      setActiveTab(updatedItem.props.id);

      if (!updatedItem.props.isDisabled) {
        updatedItem.props.onClick?.();

        if (onChange) {
          onChange(idx);
        }
      }
    },
    [onChange]
  );

  const onClickMenuItemHandle = useCallback(
    ({ idx, child }: { idx: number; child: ReactNode }) => {
      setActiveTab(idx);
      setIsDropdownOpened(false);

      // @ts-ignore
      if (!child.props.isDisabled) {
        // @ts-ignore
        child.props.onClick?.();

        if (onChange) {
          onChange(idx);
        }
      }
    },
    [onChange]
  );

  return (
    <ul
      {...props}
      ref={listRef}
      className={clsx([
        "mk-ui-tabs__list",
        "mk-ui-tabs-list",
        `mk-ui-tabs-list_size_${size}`,
        className,
      ])}
      id="tab-list"
    >
      {Children.map(children, (child, idx) => {
        const item = child as ReactElement<PropsWithChildren<TabsItemProps>>;

        const updatedItem = {
          ...item,
          props: {
            ...item.props,
            id: idx,
          },
        };
        if (updatedItem.type === TabsItem) {
          const isActive =
            updatedItem.props.id === activeTab ||
            (activeIndex === idx && !activeTab);
          return cloneElement(updatedItem, {
            className: clsx(updatedItem.props.className, {
              "mk-ui-tabs-list__item_visible": visibilityMap[idx],
              "mk-ui-tabs-list__item_hidden": !visibilityMap[idx],
            }),
            isActive,
            // @ts-ignore
            onClick: () => onClickTabHandle({ updatedItem, idx }),
            ...props,
          });
        } else {
          return child;
        }
      })}

      {hasOverflow && (
        <Dropdown
          isOpen={isDropdownOpened}
          setIsOpen={setIsDropdownOpened}
          portalId={portalId}
          minWidth={280}
          maxHeight={200}
          drop="down"
          buttonElement={
            <Button
              iconOnly
              iconName={IconMore}
              title="More..."
              size="medium"
              color="accent"
              variant="ghost"
              isActive={isDropdownActive || isDropdownOpened}
            />
          }
          menuElement={
            <Menu direction="vertical" isDropdown={true}>
              {Children.map(children, (child, idx) => {
                const item = child as ReactElement<
                  PropsWithChildren<TabsItemProps>
                >;
                const updatedItem = {
                  ...item,
                  props: {
                    ...item.props,
                    id: idx,
                  },
                };
                if (!visibilityMap[idx]) {
                  return cloneElement(
                    <Menu.Item
                      isActive={activeIndex === idx}
                      // @ts-ignore
                      {...child.props}
                    >
                      {/*@ts-ignore*/}
                      {child.props.children}
                    </Menu.Item>,
                    {
                      ...updatedItem.props,
                      onClick: () => onClickMenuItemHandle({ idx, child }),
                    }
                  );
                }
              })}
            </Menu>
          }
        />
      )}
    </ul>
  );
};

export const TabsItem = forwardRef<HTMLLIElement, TabsItemProps>(
  function TabsItem(
    {
      children,
      isActive,
      onClick,
      isDisabled,
      className,
      withDivider,
      variant = "ghost",
      color = "primary",
      ...props
    },
    ref
  ) {
    return (
      <li
        ref={ref}
        {...props}
        data-observable={true}
        role="menuitem"
        className={clsx(
          "mk-ui-tabs-list__item",
          className,
          {
            "mk-ui-tabs-list__item_active": isActive,
            "mk-ui-tabs-list__item_disabled": isDisabled,
            "mk-ui-tabs-list__item_with-divider": withDivider,
            [`mk-ui-tabs-list__item_color_${color}`]: variant !== "ghost",
          },
          `mk-ui-tabs-list__item_variant_${variant}`
        )}
        tabIndex={0}
        onKeyDown={(e) => {
          if (
            onClick &&
            (e.key === "Enter" || e.key === " " || e.key === "Spacebar")
          ) {
            onClick();
          }
        }}
        onClick={onClick}
      >
        {children}
      </li>
    );
  }
);

const TabsItemCounter: FC<{ value?: number } & TabsCounterProps> = ({
  value,
  className,
}) => {
  if (!value) {
    return null;
  }
  return (
    <span className={clsx("mk-ui-tabs-list__counter", className)}>{value}</span>
  );
};

export const TabsAside: FC<TabsAsideProps> = ({ children, className }) => {
  return <div className={clsx("mk-ui-tabs__aside", className)}>{children}</div>;
};

export default Object.assign(Tabs, {
  List: TabsList,
  Item: TabsItem,
  Counter: TabsItemCounter,
  Aside: TabsAside,
});
