import useSWR from "swr";

import { ILink, IPaginationContext } from "@types";
import { apiClient, fetcher } from "./fetcher";
import { SHORTENER_API_URL } from "const";
import { getOrderString } from "utils/getOrderString";

/* Links getters */
export enum SortBy {
  "longUrl" = "longUrl",
  "owner" = "owner",
  "date" = "date",
  "views" = "views",
  "shortUrl" = "shortUrl",
}

export type SortByValues = keyof typeof SortBy;

export enum SortDirection {
  "asc" = "asc",
  "desc" = "desc",
}

export type LinkProps = {
  data: ILink[];
  paginationContext: IPaginationContext;
};

export const orderByDefault = getOrderString([
  {
    key: SortBy.date,
    value: SortDirection.desc,
  },
]);

export const useLinkList = (
  folderId: string,
  orderBy: string,
  search?: string,
  page?: number,
  pageSize?: number
) => {
  const query = new URLSearchParams();

  orderBy && query.append("orderBy", `(${orderBy})`);
  page ? query.append("page", page.toString()) : query.append("page", "1");
  if (search) query.append("search", search);
  if (pageSize) query.append("size", pageSize.toString());

  const { data, error, mutate } = useSWR<LinkProps>(
    folderId ? `/links/${folderId}/list?${query.toString()}` : null,
    fetcher,
    { refreshInterval: 0 }
  );

  return {
    links: data?.data,
    paginationContext: data?.paginationContext,
    isLinksError: error,
    isLinksLoading: !data && !error,
    mutateLinks: mutate,
  };
};

export const useLinkByShortUrl = (shortUrl: string) => {
  const { data, error, mutate } = useSWR<ILink>(
    `/links/get-by-short-url?shortUrl=${shortUrl}`,
    fetcher,
    {
      refreshInterval: 0,
    }
  );

  return {
    link: data,
    isLinkError: error,
    isLinkLoading: !data && !error,
    mutateLink: mutate,
  };
};

/* Links CRUD */
interface IUpdateLinkData {
  url: string;
  shortUrl: string;
}

export const createLink = (url: string) => {
  return apiClient.post<ILink>("/links/create", { url });
};

export const deleteOwnLink = (id: string) => {
  return apiClient.delete(`/links/${id}`);
};

export const restoreDeletedLink = (id: string) => {
  return apiClient.post(`/links/restore-link/${id}`);
};

export const requestLinkRemoval = (id: string) => {
  return apiClient.post<string>(`/links/remove-request/${id}`);
};

export const updateLink = (id: string, data: IUpdateLinkData) => {
  return apiClient.put<string>(`/links/${id}`, data);
};

/* Favorites */

export const addFavoriteLink = (id: string) => {
  return apiClient.post<string>(`/links/favorite/${id}`);
};

export const deleteFavoriteLink = (id: string) => {
  return apiClient.delete<string>(`/links/favorite/${id}`);
};

export const checkLinkExist = (search: string) => {
  return apiClient.get<boolean>(
    `/links/check-link-is-exist?searchByUrl=${search}`
  );
};

/* Expiration Date */

export const putLinkExpirationDate = (
  linkId: string,
  expirationDate: string | null
) => {
  return apiClient.put(`/links/set-expiration-date`, {
    linkId,
    expirationDate,
  });
};

/* QR Codes */

type QRFileType = "png" | "svg";

export const useLinkQRCode = (id: string) => {
  const { data, error, mutate } = useSWR<string>(
    `/links/qrcode?Id=${id}&fileType=png`,
    fetcher,
    {
      refreshInterval: 0,
    }
  );

  return {
    QRCode: data,
    isQRCodeError: error,
    isQRCodeLoading: !data && !error,
    mutateQRCode: mutate,
  };
};

export const getQRCodePath = (id: string, fileType: QRFileType) => {
  return `${SHORTENER_API_URL}/links/qrcode?id=${id}&fileType=${fileType}`;
};

/* Folders */

export const useLinkSuitableFolders = (id: string) => {
  const { data, error, mutate } = useSWR<string[]>(
    id ? `/links/${id}/suitable-folders` : null,
    fetcher,
    {
      refreshInterval: 0,
    }
  );

  return {
    suitableFoldersIds: data,
    isSuitableFoldersError: error,
    isSuitableFoldersLoading: !data && !error,
    mutateSuitableFoldersIds: mutate,
  };
};

export const addLinkToFolder = (linkId: string, folderId: string) => {
  return apiClient.post<string>(`/links/add-to-folder/${linkId}`, { folderId });
};

export const removeLinkFromFolder = (linkId: string, folderId: string) => {
  return apiClient.delete(`/links/remove-from-folder/${linkId}`, {
    data: { folderId },
  });
};
