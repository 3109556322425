import { SERVICE_LIST } from "@mercdev.com/mercurial-ui";

export const services = (user) => {
  const serviceList = SERVICE_LIST.filter(
    (s) => s.icon !== "shortener" && user?.permissions.hasOwnProperty(s.icon)
  );

  return serviceList.map((item) => ({
    name: item.name,
    link: item.link,
    icon: item.icon,
    onClick: () => window.open(item.link, "_blank"),
  }));
};
