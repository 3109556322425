import "../FoldersManagementModal/style.scss";

import {
  Button,
  createToast,
  Heading,
  Loader,
  Modal,
  Paragraph,
} from "@mercdev.com/mercurial-ui";
import { addLinkToFolder, useLinkSuitableFolders } from "api/links";
import EmptyState from "components/EmptyState/EmptyState";
import emptyImage from "components/EmptyState/images/folders.svg";
import Folder from "components/Folder/Folder";
import { defaultFoldersSet, LINK_MESSAGES } from "const";
import React, { FC, useMemo, useState } from "react";
import { IFolder, useFolders } from "utils/FoldersContext";
import wrapIntoDefaultErrorHandler from "utils/wrapIntoDefaultErrorHandler";

import { ILink } from "/@types";

interface IProps {
  show: boolean;
  link: ILink;
  onHide: () => void;
}

const AddToFoldersModal: FC<IProps> = ({ show, link, onHide }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [checkedFolders, setCheckedFolders] = useState<IFolder[]>([]);

  const { folders, isFoldersLoading, mutateFolders } = useFolders();
  const {
    suitableFoldersIds,
    isSuitableFoldersLoading,
    mutateSuitableFoldersIds,
  } = useLinkSuitableFolders(link.id);

  const editableFolders = useMemo(
    () =>
      folders?.filter(
        (folder: IFolder) => !defaultFoldersSet.has(folder.name)
      ) || [],
    [folders]
  );

  const handleFolderCheck = (folder: IFolder) => {
    checkedFolders.find((cf) => cf.id === folder.id)
      ? setCheckedFolders((prevFolders) =>
          prevFolders.filter((pf) => pf.id !== folder.id)
        )
      : setCheckedFolders((prevFolders) => [...prevFolders, folder]);
  };

  const handleAddToFoldersClick = async () => {
    setIsLoading(true);

    await wrapIntoDefaultErrorHandler(async () => {
      for (const cf of checkedFolders) {
        await addLinkToFolder(link.id, cf.id);
      }

      createToast({
        text: LINK_MESSAGES.ADDED_TO_FOLDERS,
      });

      await mutateFolders(
        (oldFolders) =>
          oldFolders?.map((of) => ({
            ...of,
            amount: checkedFolders.find((cf) => cf.id === of.id)
              ? of.amount + 1
              : of.amount,
          })),
        false
      );

      onHide();
    });
    await mutateSuitableFoldersIds(undefined, true);
    setIsLoading(false);
  };

  return (
    <Modal
      className="folders-modal"
      width="560px"
      horizontalPadding={0}
      show={show}
      isShowCloseBtn={!isLoading}
      isOverlayClickable={isLoading ? "static" : true}
      onHide={onHide}
    >
      <div className="container">
        <Modal.Title>
          <Heading size={2}>Add the link to the folder</Heading>
        </Modal.Title>

        <Modal.Text>
          <Paragraph size={3}>
            Select the folders you want to add the link
          </Paragraph>
        </Modal.Text>
      </div>
      <section className="folders-modal__folders folders">
        {isFoldersLoading || isSuitableFoldersLoading ? (
          <div className="loader-container">
            <Loader size={48} color="accent" />
          </div>
        ) : suitableFoldersIds?.length ? (
          <>
            <Heading className="folders__heading" size={5}>
              Your folders
            </Heading>

            <div className="folders__list folders-list">
              {editableFolders
                ?.filter((f) => suitableFoldersIds?.includes(f.id))
                .map((f) => (
                  <Folder
                    key={f.id}
                    className="folders-list__folder"
                    isChecked={!!checkedFolders.find((cf) => cf.id === f.id)}
                    folder={f}
                    onCheck={() => handleFolderCheck(f)}
                  />
                ))}
            </div>
          </>
        ) : (
          <div className="empty-state-container">
            <EmptyState
              className="empty-state"
              isTight
              image={emptyImage}
              title="No suitable folders"
              description="This link already exists in all folders created"
            />
          </div>
        )}
      </section>

      <div className="add-to-folder-modal__add-button-container add-button-container">
        <Button
          className="add-button"
          variant="primary"
          isLoading={isLoading}
          isDisabled={!checkedFolders.length}
          onClick={handleAddToFoldersClick}
        >
          {checkedFolders.length === 1 ? "Move to folder" : "Move to folders"}
        </Button>
      </div>
    </Modal>
  );
};

export default AddToFoldersModal;
