import { createToast } from "@mercdev.com/mercurial-ui";

import { LINK_MESSAGES } from "const";

const copyTextToClipboard = (text: string, toastMessage: string) => {
  if (!navigator.clipboard) {
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      createToast({
        text: toastMessage,
      });
    },
    () => {
      createToast({
        text: LINK_MESSAGES.COPIED_ERROR,
        color: "negative",
      });
    }
  );
};

export default copyTextToClipboard;
