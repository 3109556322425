import "../Modal.scss";
import "./QRModal.scss";

import {
  Button,
  ButtonGroup,
  Heading,
  IconDownload,
  Modal,
  Paragraph,
} from "@mercdev.com/mercurial-ui";
import React, { FC, useMemo } from "react";
import { SHORTENER_API_URL } from "const";
import { ILink } from "../../@types";
import { getQRCodePath, useLinkQRCode } from "../../api/links";
import Loader from "../../components/Loader/Loader";

interface IProps {
  show: boolean;
  link: ILink;
  onHide: () => void;
}

const QRModal: FC<IProps> = ({ show, link, onHide }) => {
  const visibleLinkDomain = useMemo(
    () => SHORTENER_API_URL.replace("http://", "").replace("https://", ""),
    [SHORTENER_API_URL]
  );

  const { QRCode, isQRCodeError, isQRCodeLoading, mutateQRCode } =
    useLinkQRCode(link.id);

  return (
    <Modal className="modal" show={show} width="560px" onHide={onHide}>
      {isQRCodeLoading && (
        <div className="container_centered container_padding-bottom">
          <Loader size="large" />
        </div>
      )}

      <Modal.Title>
        <Heading size={3}>
          {QRCode && !isQRCodeError && "QR Code Generated"}
          {isQRCodeLoading && "QR Code is Being Generated"}
          {isQRCodeError && "An Error Occurred During QR Code Generation"}
        </Heading>
      </Modal.Title>

      <Modal.Text>
        <Paragraph size={3}>
          {QRCode && !isQRCodeError && (
            <a
              className="link-url"
              href={`${SHORTENER_API_URL}/${link.shortUrl}`}
              target="_blank"
              rel="noreferrer"
            >{`${visibleLinkDomain}/${link.shortUrl}`}</a>
          )}

          {isQRCodeLoading && "Please don’t close or refresh this window."}

          {isQRCodeError && "Please retry or try again later."}
        </Paragraph>
      </Modal.Text>

      {QRCode && !isQRCodeError && (
        <div className="container_centered">
          <img
            className="qr"
            src={getQRCodePath(link.id, "png")}
            alt="Link QR code"
          />
        </div>
      )}

      {QRCode && !isQRCodeError && (
        <Modal.FooterButtons>
          <ButtonGroup direction="horizontal">
            <ButtonGroup.Item>
              <Button
                iconName={IconDownload}
                variant="secondary"
                onClick={() => {
                  window.open(getQRCodePath(link.id, "png"));
                }}
              >
                Download PNG
              </Button>
            </ButtonGroup.Item>

            <ButtonGroup.Item>
              <Button
                iconName={IconDownload}
                variant="secondary"
                onClick={() => {
                  window.open(getQRCodePath(link.id, "svg"));
                }}
              >
                Download SVG
              </Button>
            </ButtonGroup.Item>
          </ButtonGroup>
        </Modal.FooterButtons>
      )}

      {isQRCodeError && (
        <Modal.FooterButtons>
          <Button className="retry-button" onClick={() => mutateQRCode()}>
            Retry
          </Button>
        </Modal.FooterButtons>
      )}
    </Modal>
  );
};

export default QRModal;
