import "./Modal.scss";

import { useUser } from "@mercdev.com/auth";
import {
  Button,
  ButtonGroup,
  createToast,
  Heading,
  IconTrash,
  Modal,
  Paragraph,
} from "@mercdev.com/mercurial-ui";
import { FC, useState } from "react";

import { ILink } from "../@types";
import { useFolders } from "utils/FoldersContext";
import { deleteOwnLink, requestLinkRemoval, useLinkList } from "../api/links";
import LinkInfo from "../components/LinkInfo/LinkInfo";
import { CONSTANTS, LINK_MESSAGES } from "const";
import { useLinkStore } from "../stores/LinkListStore";
import { mutateLinksData } from "utils/mutateLinksData";

interface IProps {
  show: boolean;
  link: ILink;
  onHide: () => void;
}

const LinkDeleteModal: FC<IProps> = ({ show, link, onHide }) => {
  const [folder] = useLinkStore((state) => [state.folder]);
  const [orderBy] = useLinkStore((state) => [state.orderBy]);
  const [search] = useLinkStore((state) => [state.search, state.setSearch]);
  const [page] = useLinkStore((state) => [state.page]);
  const [size] = useLinkStore((state) => [state.size]);

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUser();
  const { mutateFolders } = useFolders();
  const { mutateLinks } = useLinkList(
    folder?.id || "",
    orderBy,
    search,
    page,
    size
  );

  const isOwnLink = link.createdBy === user?.email;

  const onRemovalRequest = async () => {
    setIsLoading(true);

    try {
      await requestLinkRemoval(link.id);

      setIsLoading(false);
      onHide();

      createToast({
        text: `The link removal request has been sent to the ${link.owner} email.`,
      });
    } catch (err) {
      setIsLoading(false);

      createToast({
        text: LINK_MESSAGES.REMOVAL_REQUEST_ERROR,
        color: "negative",
      });
    }
  };

  return (
    <Modal
      className="modal"
      show={show}
      isShowCloseBtn={!isLoading}
      isOverlayClickable={isLoading ? "static" : true}
      width="560px"
      onHide={onHide}
    >
      <Modal.Icon icon={IconTrash} color={isOwnLink ? "negative" : "accent"} />

      <Modal.Title>
        <Heading size={3}>Nice Try!</Heading>
      </Modal.Title>

      <Modal.Text>
        <Paragraph size={3}>
          You can't delete other people's links, but you can send removal
          request. The link owner will receive it by email.
        </Paragraph>
      </Modal.Text>

      {!isOwnLink && <LinkInfo className="modal__link-info" link={link} />}

      <Modal.FooterButtons>
        <ButtonGroup direction="vertical">
          <ButtonGroup.Item>
            <Button
              color="accent"
              isLoading={isLoading}
              onClick={onRemovalRequest}
            >
              Request link removal
            </Button>
          </ButtonGroup.Item>

          <ButtonGroup.Item>
            <Button
              color={isOwnLink ? "negative" : "accent"}
              variant="ghost"
              isDisabled={isLoading}
              onClick={onHide}
            >
              Not now
            </Button>
          </ButtonGroup.Item>
        </ButtonGroup>
      </Modal.FooterButtons>
    </Modal>
  );
};

export default LinkDeleteModal;
