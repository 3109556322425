import "./Modal.scss";

import { useUser } from "@mercdev.com/auth";
import {
  Button,
  ButtonGroup,
  createToast,
  Heading,
  Modal,
  Paragraph,
} from "@mercdev.com/mercurial-ui";
import React, { FC, useEffect, useState } from "react";

import { ILink } from "../@types";
import { requestLinkRemoval, useLinkByShortUrl } from "../api/links";
import LinkInfo from "../components/LinkInfo/LinkInfo";
import Loader from "../components/Loader/Loader";
import { CONSTANTS, LINK_MESSAGES } from "const";

interface IProps {
  show: boolean;
  shortUrl: string;
  onDelete: (link: ILink) => void;
  onHide: () => void;
}

const LinkExistsModal: FC<IProps> = ({ show, shortUrl, onDelete, onHide }) => {
  const [isRemovalLoading, setIsRemovalLoading] = useState(false);

  const { user } = useUser();
  const { link, isLinkLoading, isLinkError } = useLinkByShortUrl(shortUrl);

  const isOwnLink = link?.createdBy === user?.email;

  useEffect(() => {
    if (!isLinkError) return;

    createToast({
      text: CONSTANTS.GENERAL_ERROR,
      color: "negative",
    });
  }, [isLinkError]);

  const onRemovalRequest = async () => {
    if (!link) return;

    setIsRemovalLoading(true);

    try {
      await requestLinkRemoval(link.id);

      setIsRemovalLoading(false);
      onHide();

      createToast({
        text: `The link removal request has been sent to the ${link.owner} email.`,
      });
    } catch (err) {
      setIsRemovalLoading(false);

      createToast({
        text: LINK_MESSAGES.REMOVAL_REQUEST_ERROR,
        color: "negative",
      });
    }
  };

  return (
    <Modal
      className="modal"
      show={show}
      isShowCloseBtn={!isRemovalLoading}
      isOverlayClickable={isRemovalLoading ? "static" : true}
      width="560px"
      onHide={onHide}
    >
      <Modal.Title>
        <Heading size={3}>The Link Already Exists</Heading>
      </Modal.Title>

      <Modal.Text>
        <Paragraph size={3}>
          {isOwnLink
            ? "You already have this link."
            : "You can send removal request. The owner will receive request by email."}
        </Paragraph>
      </Modal.Text>

      {isLinkLoading || isLinkError ? (
        <div className="container_centered container_padding-top">
          <Loader size="large" />
        </div>
      ) : (
        link && (
          <>
            <LinkInfo className="modal__link-info" link={link} />

            <Modal.FooterButtons>
              <ButtonGroup direction="vertical">
                <ButtonGroup.Item>
                  {isOwnLink ? (
                    <Button color="negative" onClick={() => onDelete(link)}>
                      Delete the link
                    </Button>
                  ) : (
                    <Button
                      color="accent"
                      isLoading={isRemovalLoading}
                      onClick={onRemovalRequest}
                    >
                      Request link removal
                    </Button>
                  )}
                </ButtonGroup.Item>

                <ButtonGroup.Item>
                  <Button
                    color={isOwnLink ? "negative" : "accent"}
                    variant="ghost"
                    isDisabled={isRemovalLoading}
                    onClick={onHide}
                  >
                    Not now
                  </Button>
                </ButtonGroup.Item>
              </ButtonGroup>
            </Modal.FooterButtons>
          </>
        )
      )}
    </Modal>
  );
};

export default LinkExistsModal;
