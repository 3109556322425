import "./Link.scss";

import { useUser, Service } from "@mercdev.com/auth";
import {
  ActionButton,
  Button,
  ButtonGroup,
  createToast,
  Dropdown,
  IconCalendar,
  IconDuplicate,
  IconFolderMinus,
  IconFolderPlus,
  IconForward,
  IconMore,
  IconQR,
  IconStar,
  IconTrash,
  Menu,
  Paragraph,
  Tooltip,
} from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import normalizeUrl from "normalize-url";
import {
  ChangeEventHandler,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { ILink } from "@types";
import { IFolder, useFolders } from "utils/FoldersContext";
import {
  addLinkToFolder,
  removeLinkFromFolder,
  updateLink,
  useLinkList,
  deleteOwnLink,
  restoreDeletedLink,
} from "../../api/links";
import {
  CONSTANTS,
  DEFAULT_FOLDERS,
  FAVORITES_MESSAGES,
  LINK_MESSAGES,
  SHORTENER_API_URL,
} from "const";
import AddToFoldersModal from "modals/AddToFoldersModal/AddToFoldersModal";
import LinkDeleteModal from "../../modals/LinkDeleteModal";
import LinkExistsModal from "modals/LinkExistsModal";
import QRModal from "../../modals/QRModal/QRModal";
import { useLinkStore } from "stores/LinkListStore";
import copyTextToClipboard from "utils/copyToClipboard";
import { useLinksContext } from "../LinkList/useLinksContext";
import Loader from "../Loader/Loader";
import Favicon from "./components/Favicon/Favicon";
import TextEditor from "./components/TextEditor/TextEditor";
import ExpirationCalendar from "modals/ExpirationCalendar/ExpirationCalendar";
import { mutateLinksData } from "utils/mutateLinksData";

interface IProps {
  className?: string;
  link: ILink;
  draggingLinkId: string;
  setDraggingLinkId: (value: string) => void;
  setInputUrlFocus: () => void;
  onAddFavorite: (
    id: string,
    callback: () => void,
    errorCallback: () => void
  ) => void;
  onDeleteFavorite: (
    id: string,
    callback: () => void,
    errorCallback: () => void
  ) => void;
  isDisabled: boolean;
}

const Link: FC<IProps> = ({
  className,
  link,
  draggingLinkId,
  setDraggingLinkId,
  setInputUrlFocus,
  onAddFavorite,
  onDeleteFavorite,
  isDisabled,
}) => {
  const textEditorRef = useRef(null);

  const [folder] = useLinkStore((state) => [state.folder]);
  const [orderBy] = useLinkStore((state) => [state.orderBy]);
  const [search] = useLinkStore((state) => [state.search, state.setSearch]);
  const [page] = useLinkStore((state) => [state.page]);
  const [size] = useLinkStore((state) => [state.size]);
  const [isNewLink, setIsNewLink] = useLinkStore((state) => [
    state.isNewLink,
    state.setIsNewLink,
  ]);
  const [isExists, setIsExists] = useLinkStore((state) => [
    state.isExists,
    state.setIsExists,
  ]);

  const { links, mutateLinks } = useLinkList(
    folder?.id || "",
    orderBy,
    search,
    page,
    size
  );

  const [editLinkType, setEditLinkType] = useState<"short" | "long" | null>(
    null
  );
  const [longUrlValue, setLongUrlValue] = useState(link.url);
  const [shortUrlValue, setShortUrlValue] = useState(link.shortUrl);
  const [linkToDelete, setLinkToDelete] = useState(link);
  const [newLink, setNewLink] = useState<boolean>(false);

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const [isExistsModalShown, setIsExistsModalShown] = useState(false);
  const [isExpirationCalendarShown, setIsExpirationCalendarShown] =
    useState(false);
  const [isQRModalShown, setIsQRModalShown] = useState(false);
  const [isMoveToFolderModalShown, setIsMoveToFolderModalShown] =
    useState(false);
  const isSubmitting = useRef(false);
  const { user } = useUser();
  const { folders, mutateFolders } = useFolders();
  const { setIdEditedLink, setHasError } = useLinksContext();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const previousLinkList = useRef<ILink[]>([]);
  const [editingLink, setEditingLink] = useState<ILink | undefined>();

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "link",
    item: link,
    options: {
      dropEffect: "copy",
    },
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    setDraggingLinkId(isDragging ? link.id : "");
  }, [isDragging, link.id, setDraggingLinkId]);

  useEffect(() => {
    if (isNewLink === link.id) {
      setNewLink(true);
      setEditLinkType("short");
    }
  }, [isNewLink]);
  useEffect(() => {
    if (editLinkType === "short" && textEditorRef.current) {
      textEditorRef.current.focusInput();
    }
  }, [editLinkType]);

  const visibleLinkDomain = useMemo(
    () => SHORTENER_API_URL,
    [SHORTENER_API_URL]
  );

  const isOwnLink = link.createdBy === user?.email;
  const canEditLink = !isOwnLink
    ? user.hasPermission(Service.Shortener, "edit-others")
    : true;

  const finishEditHandle = () => {
    setError("");
    setEditLinkType(null);
    setLongUrlValue(link.url);
    setShortUrlValue(link.shortUrl);
    setInputUrlFocus();
    setHasError(false);
    setIdEditedLink("");
  };

  const onUpdateLink = async (link) => {
    await updateLinkHandle(link)
      .then(() => {
        if (editLinkType === "short" && !newLink) {
          copyTextToClipboard(
            `${SHORTENER_API_URL}/${shortUrlValue}`,
            LINK_MESSAGES.CHANGED_COPIED
          );
          mutateLinks(async (oldLinks) => {
            return mutateLinksData(
              oldLinks,
              "edit",
              shortUrlValue,
              link.id,
              "shortUrl"
            );
          }, false);
        }
        if (editLinkType === "long" && !newLink) {
          mutateLinks(async (oldLinks) => {
            return mutateLinksData(
              oldLinks,
              "edit",
              longUrlValue,
              link.id,
              "url"
            );
          }, false);
        }
        if (newLink) {
          copyTextToClipboard(
            `${SHORTENER_API_URL}/${shortUrlValue}`,
            LINK_MESSAGES.CREATED_COPIED
          );
          setIsNewLink("");
          setNewLink(false);
        }
        finishEditHandle();
      })
      .catch((e) => {
        const backendError = e.response.data[0];
        setEditingLink(link);
        if (backendError.code === "ShortUrlAlreadyExists") {
          setError(LINK_MESSAGES.EXISTS_ERROR);
          setIsExistsModalShown(true);
        } else {
          backendError.description
            ? setError(backendError.description)
            : setError(CONSTANTS.GENERAL_ERROR);
        }

        setHasError(true);
        setIdEditedLink(link.id);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitValue = async () => {
    if (!isSubmitting.current) {
      isSubmitting.current = true;
      if (
        (editLinkType === "short" && shortUrlValue === link.shortUrl) ||
        (editLinkType === "long" && longUrlValue === link.url)
      ) {
        if (newLink) {
          copyTextToClipboard(
            `${SHORTENER_API_URL}/${shortUrlValue}`,
            LINK_MESSAGES.CREATED_COPIED
          );
          setIsNewLink("");
          setNewLink(false);
        }
        finishEditHandle();
        isSubmitting.current = false;
        return;
      }

      if (error) {
        if (!isExistsModalShown) {
          finishEditHandle();
          setInputUrlFocus();
        }
        isSubmitting.current = false;
        return;
      }

      setError("");
      setIsLoading(true);
      onUpdateLink(link);

      isSubmitting.current = false;
    }
  };

  const onUrlSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await submitValue();
  };

  const updateLinkHandle = async (link: ILink) => {
    const linkData = {
      url: normalizeUrl(longUrlValue, {
        forceHttps: !longUrlValue.includes("http://"),
      }),
      shortUrl: shortUrlValue,
    };

    await updateLink(link.id, linkData);

    setLongUrlValue(
      normalizeUrl(longUrlValue, {
        forceHttps: !longUrlValue.includes("http://"),
      })
    );

    await mutateLinks(async (oldLinks) => {
      return mutateLinksData(
        oldLinks,
        "edit",
        shortUrlValue,
        link.id,
        "shortUrl"
      );
    }, false);
  };

  const onLinkClick = useCallback(() => {
    if (editLinkType === "long") {
      return null;
    }
    setEditLinkType("short");
  }, [editLinkType]);

  const handleEditLongUrlClick = () => {
    canEditLink && setEditLinkType("long");
  };

  const handleEditShortUrlClick = () => {
    canEditLink && setEditLinkType("short");
  };

  const onOpenHandle = async () => {
    await mutateLinks((oldLinks) => {
      return mutateLinksData(
        oldLinks,
        "edit",
        link.views + 1,
        link.id,
        "views"
      );
    }, false);

    window.open(`${SHORTENER_API_URL}/${link.shortUrl}`, "_blank");
  };

  const restoreLink = async (restoringLink: ILink | undefined) => {
    const currentLink = restoringLink ? restoringLink : link;
    setIsLoading(true);
    try {
      await restoreDeletedLink(currentLink.id);

      await mutateLinks((oldLinks) => {
        return { ...oldLinks, data: previousLinkList.current };
      }, false);
      let myFolder = undefined;
      if (folder.name === DEFAULT_FOLDERS.ALL) {
        myFolder = folders.find(
          (folder: IFolder) => folder.name === DEFAULT_FOLDERS.MINE
        );
      }
      await mutateFolders(
        async (oldFolders: IFolder[]) =>
          (oldFolders || []).map((of) => ({
            ...of,
            amount:
              of.id === folder?.id || of.id === myFolder?.id
                ? of.amount + 1
                : of.amount,
          })),
        false
      );

      setIsLoading(false);
    } catch (err) {
      createToast({
        text: CONSTANTS.GENERAL_ERROR,
        color: "negative",
      });
    }
  };

  const onDeleteHandle = async (deletingLink?: ILink | undefined) => {
    const currentLink = deletingLink ? deletingLink : link;
    const isOwnCurrentLink = currentLink.createdBy === user?.email;
    if (isOwnCurrentLink) {
      setIsLoading(true);
      previousLinkList.current = links.map((el) => el);
      try {
        await deleteOwnLink(currentLink.id);
        await mutateLinks((oldLinks) => {
          return mutateLinksData(oldLinks, "delete", undefined, currentLink.id);
        }, false);
        await mutateFolders();

        setIsLoading(false);
        createToast({
          color: !deletingLink ? "dark" : "light",
          text: !deletingLink
            ? `The link “${visibleLinkDomain}/${shortUrlValue}” has been deleted.`
            : "The link has been deleted",
          footer: !deletingLink ? (
            <ButtonGroup gap="small">
              <ButtonGroup.Item>
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() => restoreLink(currentLink)}
                >
                  Undo
                </Button>
              </ButtonGroup.Item>
            </ButtonGroup>
          ) : null,
        });
      } catch (err) {
        setIsLoading(false);

        err.response.data[0].description
          ? createToast({
              text: err.response.data[0].description,
              color: "negative",
            })
          : createToast({
              text: CONSTANTS.GENERAL_ERROR,
              color: "negative",
            });
      }
    } else {
      setIsDeleteModalShown(true);
    }
  };

  const addToFavorites = (id: string) => {
    setIsLoading(true);

    onAddFavorite(
      id,
      () => {
        setIsLoading(false);
        createToast({
          text: FAVORITES_MESSAGES.ADDED,
        });
      },
      () => {
        setIsLoading(false);
        createToast({
          text: CONSTANTS.GENERAL_ERROR,
          color: "negative",
        });
      }
    );
    if (folder.name === DEFAULT_FOLDERS.FAVORITES) {
      mutateLinks((oldLinks) => {
        return mutateLinksData(oldLinks, "add", link);
      }, false);
    }
  };

  const deleteFromFavorites = (id: string) => {
    setIsLoading(true);

    onDeleteFavorite(
      id,
      () => {
        setIsLoading(false);

        createToast({
          text: FAVORITES_MESSAGES.REMOVED,
          color: "dark",
          footer: (
            <ButtonGroup gap="small">
              <ButtonGroup.Item>
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() => addToFavorites(id)}
                >
                  Undo
                </Button>
              </ButtonGroup.Item>
            </ButtonGroup>
          ),
        });
      },
      () => {
        setIsLoading(false);

        createToast({
          text: CONSTANTS.GENERAL_ERROR,
          color: "negative",
        });
      }
    );
  };

  const undoAddToFolder = async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    try {
      await addLinkToFolder(link.id, folder?.id || "");

      await mutateLinks((oldLinks) => {
        return mutateLinksData(oldLinks, "add", link);
      }, false);
      await mutateFolders(
        async (oldFolders: IFolder[]) =>
          (oldFolders || []).map((of) => ({
            ...of,
            amount: of.id === folder?.id ? of.amount + 1 : of.amount,
          })),
        false
      );
    } catch (e) {
      const backendError = e.response.data[0];

      backendError?.description
        ? createToast({
            text: backendError.description,
            color: "negative",
          })
        : createToast({
            text: CONSTANTS.GENERAL_ERROR,
            color: "negative",
          });
    }
  };

  const onDeleteModalHide = async () => {
    if (linkToDelete.id !== link.id) {
      await submitValue();
    }

    setIsDeleteModalShown(false);
    setInputUrlFocus();
  };

  const onExistModalDelete = async (link: ILink) => {
    const newLink = { ...editingLink, shortUrl: shortUrlValue };
    setIsLoading(true);
    await onDeleteHandle(link);
    await onUpdateLink(newLink);
    setError("");
    setIsExistsModalShown(false);
    setIsLoading(false);
  };

  const onExistsModalHide = () => {
    setShortUrlValue(link.shortUrl);
    finishEditHandle();
    setIsExistsModalShown(false);
    setInputUrlFocus();
  };

  const onExpirationModalHide = () => {
    setIsExpirationCalendarShown(false);
    setInputUrlFocus();
  };

  const onShortUrlChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setShortUrlValue(e.target.value.replace(/\s/g, ""));
    setError("");
  };

  const onRemoveFromFolder = async () => {
    setIsLoading(true);

    try {
      await removeLinkFromFolder(link.id, folder?.id || "");

      await mutateLinks(async (oldLinks) => {
        return mutateLinksData(oldLinks, "delete", undefined, link.id);
      }, false);

      await mutateFolders(
        async (oldFolders) =>
          (oldFolders || []).map((of) => ({
            ...of,
            amount: of.id === folder?.id ? of.amount - 1 : of.amount,
          })),
        false
      );

      createToast({
        color: "light",
        text: `The link has been removed from "${folder?.name}" folder.`,
        footer: (
          <ButtonGroup gap="small">
            <ButtonGroup.Item>
              <Button
                variant="secondary"
                size="small"
                onClick={undoAddToFolder}
              >
                Undo
              </Button>
            </ButtonGroup.Item>
          </ButtonGroup>
        ),
      });
    } catch (e) {
      const backendError = e.response.data[0];

      backendError?.description
        ? createToast({
            text: backendError.description,
            color: "negative",
          })
        : createToast({
            text: CONSTANTS.GENERAL_ERROR,
            color: "negative",
          });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (link.isNewLink) {
      onLinkClick();
      link.isNewLink = false;
    }
  }, [onLinkClick, link]);

  return (
    <li
      className={cn(
        className,
        newLink && "link-new",
        "link --color-text-80 mk-ui-paper",
        {
          link_active: isDropdownOpened,
          link_editing: editLinkType || link.id === draggingLinkId,
          link_favorite: link.isFavorite,
          link_disabled:
            isLoading ||
            isDisabled ||
            (draggingLinkId && link.id !== draggingLinkId),
        }
      )}
      ref={drag}
      /* A crutch for Safari */
      onMouseDown={(e) => e.preventDefault()}
    >
      <div
        className={cn("long-url-column link__long-url long-url", {
          "long-url_active": editLinkType === "long",
        })}
      >
        <div className="long-url__icon-area icon-area">
          <Favicon
            className="link__favicon"
            src={`${SHORTENER_API_URL}/links/favicon/${link.id}`}
          />

          <ActionButton
            className={cn("icon-area__star-button", "star-button", {
              "star-button_favorite": link.isFavorite,
            })}
            variant="secondary"
            isSquared
            iconName={IconStar}
            size="small"
            color={link.isFavorite ? "light" : "accent"}
            isActive={link.isFavorite}
            title=""
            onClick={
              link.isFavorite
                ? () => deleteFromFavorites(link.id)
                : () => addToFavorites(link.id)
            }
            aria-label="add to favorite"
          />
        </div>

        <span
          className={cn(
            search && isExists && "long-url__value-search",
            "long-url__value"
          )}
          onClick={handleEditLongUrlClick}
          id={`${link.id}-long-link`}
        >
          {link.url}
        </span>
        <Tooltip place="top-start" id={`${link.id}-long-link`} theme="accent">
          {link.url}
        </Tooltip>

        {editLinkType === "long" && (
          <TextEditor
            className="long-url__text-editor"
            value={longUrlValue}
            isDisabled={isLoading}
            onChange={(e) => setLongUrlValue(e.target.value.replace(/\s/g, ""))}
            onSubmit={onUrlSubmit}
            onBlur={submitValue}
          />
        )}
      </div>

      <div className="owner-column">
        <Paragraph size={5}>{link.owner}</Paragraph>
      </div>

      <div className="link__date-added date-added-column">
        <Paragraph size={5}>
          {new Date(link.createdAt).toLocaleDateString(
            navigator.languages?.[0] || navigator.language
          )}
        </Paragraph>

        {link.expirationDate && (
          <div className="expiration-date__wrapper">
            <Paragraph
              id={`expiration-date-${link.id}`}
              className="expiration-date"
              size={6}
            >
              {`Exp. ${new Date(link.expirationDate).toLocaleDateString(
                navigator.languages?.[0] || navigator.language
              )}`}
            </Paragraph>

            <Tooltip
              id={`expiration-date-${link.id}`}
              place="bottom"
              theme="dark"
            >
              Expiration date
            </Tooltip>
          </div>
        )}
      </div>

      <div className="views-column">
        <Paragraph size={5}>{link.views}</Paragraph>
      </div>

      <div
        className={cn(
          "short-link-column link__short-url short-url",
          newLink && "short-url__new",
          {
            "short-url_active": editLinkType === "short",
          }
        )}
        onClick={handleEditShortUrlClick}
      >
        <span className="short-url__prefix">{visibleLinkDomain}/</span>
        <span className="short-url__value">{link.shortUrl}</span>

        {editLinkType === "short" && (
          <TextEditor
            ref={textEditorRef}
            className="short-url__text-editor"
            value={shortUrlValue}
            isDisabled={isLoading}
            error={error}
            onChange={onShortUrlChange}
            onSubmit={onUrlSubmit}
            onBlur={submitValue}
            onFocus={(e) => e.target.select()}
          />
        )}
      </div>

      <div
        className={cn("link__actions actions", {
          link__actions_active: isDropdownOpened,
        })}
      >
        <ActionButton
          className="link__btn-copy"
          variant="secondary"
          iconName={IconDuplicate}
          title="Copy"
          size="medium"
          isSquared={true}
          onClick={() =>
            copyTextToClipboard(
              `${SHORTENER_API_URL}/${link.shortUrl}`,
              LINK_MESSAGES.COPIED
            )
          }
        />

        <Dropdown
          portalId="portal-container"
          isOpen={isDropdownOpened}
          setIsOpen={setIsDropdownOpened}
          offset={[0, 8]}
          alignEnd
          buttonElement={
            <Button
              className={cn("action", { action_active: isDropdownOpened })}
              iconOnly
              iconName={IconMore}
              title="More..."
              size="medium"
              color="accent"
              variant="ghost"
              isActive={isDropdownOpened}
              onClick={() => setIsDropdownOpened((value) => !value)}
            />
          }
          menuElement={
            <Menu
              className="dropdown-menu"
              direction="vertical"
              isDropdown={true}
            >
              <Menu.Item icon={IconForward} onClick={onOpenHandle}>
                Forward
              </Menu.Item>

              {isOwnLink && (
                <Menu.Item
                  icon={IconCalendar}
                  onClick={() => setIsExpirationCalendarShown(true)}
                >
                  {link.expirationDate
                    ? "Edit expiration date"
                    : "Add expiration date"}
                </Menu.Item>
              )}

              <Menu.Item icon={IconQR} onClick={() => setIsQRModalShown(true)}>
                Generate QR code
              </Menu.Item>

              <Menu.Item
                withDivider={
                  (folder?.name === DEFAULT_FOLDERS.MINE ||
                    folder?.name === DEFAULT_FOLDERS.FAVORITES ||
                    folder?.name === DEFAULT_FOLDERS.ALL) &&
                  canEditLink
                }
                icon={IconFolderPlus}
                onClick={() => setIsMoveToFolderModalShown(true)}
              >
                Add to folders
              </Menu.Item>

              {!(
                folder?.name === DEFAULT_FOLDERS.MINE ||
                folder?.name === DEFAULT_FOLDERS.FAVORITES ||
                folder?.name === DEFAULT_FOLDERS.ALL
              ) && (
                <Menu.Item
                  withDivider={canEditLink}
                  icon={IconFolderMinus}
                  onClick={onRemoveFromFolder}
                >
                  Remove from folder
                </Menu.Item>
              )}

              {canEditLink && (
                <Menu.Item icon={IconTrash} onClick={() => onDeleteHandle()}>
                  Delete
                </Menu.Item>
              )}
            </Menu>
          }
        />
      </div>

      {isLoading && <Loader size={"normal"} className="link__loader" />}

      {!isOwnLink && (
        <LinkDeleteModal
          show={isDeleteModalShown}
          link={linkToDelete}
          onHide={onDeleteModalHide}
        />
      )}

      {isExistsModalShown && (
        <LinkExistsModal
          show={isExistsModalShown}
          shortUrl={shortUrlValue}
          onDelete={onExistModalDelete}
          onHide={onExistsModalHide}
        />
      )}

      {isExpirationCalendarShown && (
        <ExpirationCalendar
          showModalCalendar={isExpirationCalendarShown}
          link={link}
          handleHideModal={onExpirationModalHide}
        />
      )}

      {isQRModalShown && (
        <QRModal
          show={isQRModalShown}
          link={link}
          onHide={() => setIsQRModalShown(false)}
        />
      )}

      {isMoveToFolderModalShown && (
        <AddToFoldersModal
          show={isMoveToFolderModalShown}
          link={link}
          onHide={() => setIsMoveToFolderModalShown(false)}
        />
      )}
    </li>
  );
};

export default Link;
