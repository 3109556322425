import axios from "axios";

export const apiClient = axios.create({
  withCredentials: true,
  baseURL: import.meta.env.VITE_SHORTENER_API_URL,
});

export const fetcher = async (url: string) => {
  const { data } = await apiClient.get(url);
  return data;
};
