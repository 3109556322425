import "./style.scss";

import {
  Button,
  IconCheckmark,
  IconCross,
  TextInput,
} from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import { FC, FocusEventHandler, HTMLAttributes, useState } from "react";

type BaseProps = {
  value?: string;
  onSubmit: (value: string) => void;
  onCancel: () => void;
};

type Props = BaseProps & Omit<HTMLAttributes<HTMLDivElement>, keyof BaseProps>;

const FolderAction: FC<Props> = ({
  className,
  value = "",
  onSubmit,
  onCancel,
}) => {
  const [localValue, setLocalValue] = useState(value);

  const [isLoading, setLoading] = useState(false);

  const handleSubmitClick = async (value: string) => {
    setLoading(true);
    await onSubmit(value);
    setLoading(false);
  };

  const handleInputBlur: FocusEventHandler = (e) => {
    if (e.relatedTarget?.id === "submit" || !e.relatedTarget) return;

    onCancel();
  };

  return (
    <div className={cn(className, "folder-action")}>
      <TextInput
        autoFocus
        className="folder-action__input"
        value={localValue}
        isLoading={isLoading}
        onChange={(v) => setLocalValue(v)}
        onEnterKeyPress={() => onSubmit(localValue)}
        onBlur={handleInputBlur}
        size="medium"
      />

      <div className="buttons">
        <Button
          id="submit"
          iconOnly
          iconName={IconCheckmark}
          variant="third"
          color="accent"
          isDisabled={isLoading}
          onClick={() => handleSubmitClick(localValue)}
        ></Button>

        <Button
          iconOnly
          iconName={IconCross}
          variant="third"
          color="negative"
          isDisabled={isLoading}
          onClick={onCancel}
        ></Button>
      </div>
    </div>
  );
};

export default FolderAction;
