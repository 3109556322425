export const moveItemToPosition = (
  arr: any,
  id: string,
  position: number
): any[] => {
  const index = arr.findIndex((item) => item.id === id);
  const item = arr[index];
  const newArray = [...arr.slice(0, index), ...arr.slice(index + 1)];
  newArray.splice(position, 0, item);

  return newArray;
};
