import { create } from "zustand";
import { IFolder } from "api/folders";

interface FoldersListStore {
  foldersList: IFolder[];

  setFoldersList: (v: IFolder[]) => void;
}

export const useFoldersListStore = create<FoldersListStore>((set) => ({
  foldersList: [],

  setFoldersList: (v) => set({ foldersList: v }),
}));
