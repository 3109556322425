import { AuthProvider, AuthWall, Service } from "@mercdev.com/auth";
import { ToastContainer } from "@mercdev.com/mercurial-ui";
import DummyPage from "components/DummyPage/DummyPage";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useUser } from "utils/UserContext";

import { apiClient } from "./api/fetcher";
import App from "./components/App/App";
import { NotFoundScreen } from "./components/NotFoundScreen/NotFoundScreen";

export const Root = () => {
  const { setUser } = useUser();

  return (
    <AuthProvider
      apiUrl={import.meta.env.VITE_HUB_API_URL}
      hubUrl={import.meta.env.VITE_HUB_URL}
      redirectUri={import.meta.env.VITE_CLIENT_URL}
      onUserUpdate={(user) => {
        console.log("Получили/обновили пользователя", user);
        apiClient.defaults.headers.common.Authorization =
          "Bearer " + user.token.accessToken;
        setUser(user);
      }}
    >
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <AuthWall
                  requiredPermissions={{ [Service.Shortener]: ["use-portal"] }}
                  noAuthComponent={<DummyPage />}
                >
                  <App />
                  <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    limit={3}
                  />
                </AuthWall>
              }
            />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </BrowserRouter>
      </DndProvider>
    </AuthProvider>
  );
};
