import "./NotFoundScreen.scss";

import {
  Branding,
  Display,
  Heading,
  Paragraph,
} from "@mercdev.com/mercurial-ui";
import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";

import RiveDoge from "./Doge.riv";

export const NotFoundScreen = () => {
  const shortenerUrl = import.meta.env.VITE_CLIENT_URL;

  const { RiveComponent: DogeBack } = useRive({
    src: RiveDoge,
    artboard: "Back",
    stateMachines: "State Machine Back",
    layout: new Layout({
      fit: Fit.FitHeight,
      alignment: Alignment.TopLeft,
    }),
    autoplay: true,
  });

  const { RiveComponent: DogeHead } = useRive({
    src: RiveDoge,
    artboard: "Head",
    stateMachines: "State Machine Head",
    layout: new Layout({
      fit: Fit.FitHeight,
      alignment: Alignment.TopRight,
    }),
    autoplay: true,
  });

  return (
    <div className="not-found">
      <div className="logo-container">
        <a className="logo-link" href={shortenerUrl}>
          <Branding className="logo-container__logo">
            <Branding.Icon icon="shortener" />

            <Branding.Logo
              title="Shortener"
              description="by Mercury Development"
            />
          </Branding>
        </a>
      </div>

      <div className="not-found__content content">
        <DogeHead className="doge-container" />

        <div className="content__text-container text-container">
          <Display className="text-container__text-404 text-404">404</Display>

          <Heading size={2} className="text-container__text-not-found">
            Short link not found
          </Heading>

          <Paragraph size={2}>
            You seem to have clicked on a broken link or entered a URL that
            doesn’t exist.
          </Paragraph>
        </div>

        <DogeBack className="doge-container" />
      </div>
    </div>
  );
};
