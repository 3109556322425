import "./LinkList.scss";

import cn from "classnames";
import memoize from "memoize-one";
import { FC, useEffect, useState } from "react";

import { useFolders } from "utils/FoldersContext";
import { useLinkList } from "api/links";
import useSignalR from "hooks/useSignalR";
import { useLinkStore } from "stores/LinkListStore";
import EmptyState from "../EmptyState/EmptyState";
import emptyImage from "../EmptyState/images/something-wrong.svg";
import { useLinksContext } from "./useLinksContext";
import Folders from "./components/Folders/Folders";
import { LinkTable } from "./components/LinkTable";
import { useFoldersListStore } from "stores/FoldersListStore";
import SkeletonPage from "components/SkeletonPage/SkeletonPage";

interface IProps {
  setInputUrlFocus: () => void;
}

const LinkList: FC<IProps> = ({ setInputUrlFocus }) => {
  const [folder, setFolder] = useLinkStore((state) => {
    return [state.folder, state.setFolder];
  });
  const [orderBy] = useLinkStore((state) => [state.orderBy]);
  const [search] = useLinkStore((state) => [state.search, state.setSearch]);
  const [page, setPage] = useLinkStore((state) => [state.page, state.setPage]);
  const [size, setPageSize] = useLinkStore((state) => {
    return [state.size, state.setPageSize];
  });
  const [setFoldersList] = useFoldersListStore((state) => [
    state.setFoldersList,
  ]);

  const [draggingLinkId, setDraggingLinkId] = useState("");

  const { folders, isFoldersLoading, isFoldersError } = useFolders();
  const { links, paginationContext, isLinksError, isLinksLoading } =
    useLinkList(folder?.id || "", orderBy, search, page, size);

  useSignalR();

  const { hasError, idEditedLink } = useLinksContext();
  useEffect(() => {
    if (!folders || isFoldersLoading || isFoldersError || folder) return;
    setFolder(folders[0]);
    setFoldersList(folders);
  }, [folder, folders, isFoldersError, isFoldersLoading, setFolder]);

  const createItemData = memoize(
    (
      items,
      paginationContext,
      draggingLinkId,
      setDraggingLinkId,
      folder,
      setInputUrlFocus,
      hasError,
      idEditedLink
    ) => ({
      items,
      paginationContext,
      draggingLinkId,
      setDraggingLinkId,
      folder,
      setInputUrlFocus,
      hasError,
      idEditedLink,
    })
  );

  const itemData = createItemData(
    links,
    paginationContext,
    draggingLinkId,
    setDraggingLinkId,
    folder,
    setInputUrlFocus,
    hasError,
    idEditedLink
  );

  if (isFoldersLoading) {
    return <SkeletonPage />;
  }

  if (isFoldersError || isLinksError) {
    return (
      <div className="list-centering-container">
        <EmptyState
          image={emptyImage}
          title="Something went wrong!"
          description="Sorry, the service is currently unavailable, we are working on it. Please come back later."
        />
      </div>
    );
  }

  return (
    <section
      className={cn("link-list", {
        "link-list_loaded": !isLinksLoading,
      })}
    >
      <div className="link-list__item link-list-folders">
        <div className="link-list-folders__inner">
          <Folders
            isProceedingDrop={!!draggingLinkId}
            draggingLinkId={draggingLinkId}
          />
        </div>
      </div>
      <div className="link-list__wrapper">
        <LinkTable itemData={itemData} />
      </div>
    </section>
  );
};

export default LinkList;
