import "./LinkInfo.scss";

import { IconForward, Paragraph } from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import { FC, useMemo } from "react";
import { SHORTENER_API_URL } from "const";
import { ILink } from "../../@types";

interface IProps {
  className?: string;
  link: ILink;
}

const LinkInfo: FC<IProps> = ({ className, link }) => {
  const visibleLinkDomain = useMemo(
    () =>
      SHORTENER_API_URL.includes("http") && SHORTENER_API_URL.split("//")[1],
    [SHORTENER_API_URL]
  );

  return (
    <div className={cn(className, "link-info")}>
      <a
        className="link-info-url"
        href={`${SHORTENER_API_URL}/${link.shortUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        {`${visibleLinkDomain}/${link.shortUrl}`}

        <IconForward
          className="link-info__forward-icon forward-icon"
          size={16}
        />
      </a>

      <div className="link-info__columns link-info-columns">
        <div>
          <Paragraph className="column-title" size={5}>
            Owner
          </Paragraph>

          <Paragraph size={5} isHighlighted>
            {link.owner}
          </Paragraph>
        </div>

        <div>
          <Paragraph className="column-title" size={5}>
            Views
          </Paragraph>

          <Paragraph size={5} isHighlighted>
            {link.views}
          </Paragraph>
        </div>

        <div>
          <Paragraph className="column-title" size={5}>
            Expiration Date
          </Paragraph>

          <Paragraph size={5} isHighlighted>
            {link.expirationDate
              ? new Date(link.expirationDate).toLocaleDateString(
                  navigator.languages?.[0] || navigator.language
                )
              : "—"}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default LinkInfo;
