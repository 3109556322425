import "./EmptyState.scss";

import { Heading, Paragraph } from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import { FC, HTMLAttributes } from "react";

type Props = {
  image?: string;
  title: string;
  description: string;
  isTight?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const EmptyState: FC<Props> = ({
  className,
  image,
  title,
  description,
  isTight = false,
  ...props
}) => {
  return (
    <div
      className={cn(className, "empty-state", isTight && "empty-state_tight")}
      {...props}
    >
      <img src={image} alt="" />

      <Heading className="empty-state__heading" size={2}>
        {title}
      </Heading>

      <Paragraph className="description" size={3}>
        {description}
      </Paragraph>
    </div>
  );
};

export default EmptyState;
