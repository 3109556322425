import { useRef } from "react";

const useFocus = () => {
  const inputUrlRef = useRef<HTMLInputElement | null>(null);

  const setInputUrlFocus = () => {
    if (inputUrlRef.current) {
      inputUrlRef.current.focus();
    }
  };

  return { inputUrlRef, setInputUrlFocus };
};

export default useFocus;
