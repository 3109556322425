import { useUser } from "utils/UserContext";
import { Branding } from "@mercdev.com/mercurial-ui";
import { services } from "utils/generateServiceList";

const Footer = ({}) => {
  const { user } = useUser();

  return (
    <div className="app__footer mk-ui-paper">
      <div className="app__footer-wrapper">
        <div>
          <p className="app__footer-header">Shortener, 2022–2024</p>
          <a
            className="app__footer-link"
            href="mailto:artdir@mercurydevelopment.com"
          >
            artdir@mercurydevelopment.com
          </a>
        </div>
        <div className="app__footer-links">
          {services(user).map((item) => {
            return (
              <li
                role="tab"
                tabIndex={0}
                key={`mk-ui-menu-branding-item-${item.name}`}
                className={"app__footer-icon"}
                onClick={item.onClick}
                onKeyDown={(event) => {
                  if (
                    item.onClick &&
                    (event.code === "Space" || event.code === "Enter")
                  ) {
                    item.onClick();
                  }
                }}
              >
                <span className="mk-ui-menu-branding__icon">
                  <Branding isGrayscale withoutAnimation>
                    <Branding.Icon icon={item.icon} iconSize="medium" />
                  </Branding>
                </span>
              </li>
            );
          })}
        </div>
        <div>
          <p className="app__footer-header">
            © Mercury Development, 1999–2024
          </p>
          <a className="app__footer-link" href="https://mercdev.com">
            mercdev.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
