import { createToast } from "@mercdev.com/mercurial-ui";

import { CONSTANTS } from "const";

const wrapIntoDefaultErrorHandler = async (callback: () => void) => {
  try {
    await callback();
  } catch (e) {
    const backendError = e.response.data[0];

    backendError?.description
      ? createToast({
          text: backendError.description,
          color: "negative",
        })
      : createToast({
          text: CONSTANTS.GENERAL_ERROR,
          color: "negative",
        });
  }
};

export default wrapIntoDefaultErrorHandler;
