import "./style.scss";

import { Button, createToast, IconFolderPlus } from "@mercdev.com/mercurial-ui";
import cn from "classnames";
import { useEffect, useState, useRef } from "react";

import { IFolder, useFolders } from "utils/FoldersContext";
import {
  addLinkToFolder,
  useLinkList,
  useLinkSuitableFolders,
} from "api/links";
import { CONSTANTS, LINK_MESSAGES, defaultFoldersSet } from "const";
import FoldersManagementModal from "modals/FoldersManagementModal/FoldersManagementModal";
import { useLinkStore } from "stores/LinkListStore";
import DroppableTabItem from "../DroppableTabItem/DroppableTabItem";
import { useFoldersListStore } from "stores/FoldersListStore";
import { moveItemToPosition } from "utils/moveItemToPosition";
import { Tabs, TabsList, TabsItem, TabsAside } from "components/Tabs/Tabs";
interface IProps {
  isProceedingDrop?: boolean;
  draggingLinkId: string;
}

const flexibleTabIndex = 3;

const Folders = ({ isProceedingDrop = false, draggingLinkId }: IProps) => {
  const [foldersList, setFoldersList] = useFoldersListStore((state) => [
    state.foldersList,
    state.setFoldersList,
  ]);
  const currentTabs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [folder, setFolder] = useLinkStore((state) => [
    state.folder,
    state.setFolder,
  ]);
  const [page, setPage] = useLinkStore((state) => [state.page, state.setPage]);
  const [pageSize] = useLinkStore((state) => [state.size]);
  const { folders, isFoldersLoading, isFoldersError, mutateFolders } =
    useFolders();
  const { suitableFoldersIds, mutateSuitableFoldersIds } =
    useLinkSuitableFolders(draggingLinkId);
  const [orderBy, setOrderBy] = useLinkStore((state) => [
    state.orderBy,
    state.setOrder,
  ]);
  const [isFoldersModalShown, setIsFoldersModalShown] = useState(false);
  const [loadingFolderId, setLoadingFolderId] = useState("");
  const [fromMenu, setFromMenu] = useState(false);
  const { mutateLinks } = useLinkList(folder?.id, orderBy, "", page, pageSize);

  useEffect(() => {
    const newFolders = fromMenu
      ? moveItemToPosition(folders, folder.id, flexibleTabIndex)
      : folders;
    setFoldersList(newFolders);
  }, [folders, fromMenu, folder, flexibleTabIndex]);

  const onSetFolder = (folder: IFolder) => {
    const index = foldersList?.findIndex((item) => item.id === folder.id);
    const isHiddenOrDroppable =
      currentTabs.current[index].classList.contains(
        "mk-ui-tabs-list__item_hidden"
      ) || currentTabs.current[index].classList.contains("droppable-tab-item");

    const newFolders = isHiddenOrDroppable
      ? moveItemToPosition(folders, folder.id, flexibleTabIndex)
      : folders;
    const newActiveIndex = isHiddenOrDroppable
      ? flexibleTabIndex
      : folders.findIndex((item) => item.id === folder.id);

    setFoldersList(newFolders);
    setActiveIndex(newActiveIndex);
    setFromMenu(isHiddenOrDroppable);
    setFolder(folder);
    setPage(1);
    setOrderBy(orderBy);
    mutateLinks(undefined, true);
  };

  useEffect(() => {
    if (!isFoldersError) return;

    createToast({
      text: CONSTANTS.GENERAL_ERROR,
      color: "negative",
    });
  }, [isFoldersError]);

  const handleLinkDrop = async (linkId: string, folder: IFolder) => {
    setLoadingFolderId(folder.id);

    try {
      await addLinkToFolder(linkId, folder.id);

      createToast({
        text: `The link has been added to "${folder.name}" folder.`,
      });

      await mutateFolders(
        async (oldFolders: IFolder[]) =>
          (oldFolders || []).map((of) => ({
            ...of,
            amount: of.id === folder.id ? of.amount + 1 : of.amount,
          })),

        false
      );

      await mutateSuitableFoldersIds();
    } catch (e) {
      const backendError = e.response.data[0];

      backendError?.description
        ? createToast({
            text: `This link is already in the “${folder.name}” folder`,
            color: "light",
          })
        : createToast({
            text: LINK_MESSAGES.DROPPED_TO_FOLDER_ERROR,
            color: "negative",
          });
    }

    setLoadingFolderId("");
  };

  if (isFoldersLoading || isFoldersError) return null;

  const dividerIndex = (idx) => {
    const nullIndex = foldersList.findIndex(
      (folder) => folder.orderNumber === null
    );
    const dividerIndex =
      nullIndex > 1
        ? nullIndex
        : foldersList.findIndex((folder) => folder.orderNumber === 1);
    return dividerIndex === idx;
  };

  return (
    <>
      <Tabs
        className={cn("folders-tabs", {
          "folders-tabs_active": isProceedingDrop,
        })}
      >
        <TabsList
          portalId="portal-container"
          size="medium"
          activeIndex={activeIndex}
          role="menubar"
        >
          {foldersList?.map((folder, idx) => (
            <TabsItem
              ref={(el) => (currentTabs.current[idx] = el)}
              className={cn(
                "folder-item",
                idx === activeIndex && "folder-item_active"
              )}
              key={folder.name}
              withDivider={dividerIndex(idx)}
              isDisabled={
                (isProceedingDrop && defaultFoldersSet.has(folder.name)) ||
                (!!loadingFolderId && folder.id !== loadingFolderId)
              }
              onClick={() => onSetFolder(folder)}
              role="menuitem"
            >
              <DroppableTabItem
                key={folder.name}
                text={folder.name}
                counter={folder.amount}
                isLoading={folder.id === loadingFolderId}
                onLinkDrop={(item) => handleLinkDrop(item.id, folder)}
              />
            </TabsItem>
          ))}
        </TabsList>

        <TabsAside>
          <Button
            className="manage-button"
            size="medium"
            variant="third"
            iconName={IconFolderPlus}
            isDisabled={isProceedingDrop}
            onClick={() => setIsFoldersModalShown(true)}
          >
            Manage folders
          </Button>
        </TabsAside>
      </Tabs>

      {folder && (
        <FoldersManagementModal
          show={isFoldersModalShown}
          onHide={() => setIsFoldersModalShown(false)}
        />
      )}
    </>
  );
};

export default Folders;
