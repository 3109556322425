import { createContext, useContext } from "react";

export type LinksContextType = {
  idEditedLink: string | null;
  setIdEditedLink: (id: string) => void;
  hasError: boolean;
  setHasError: (v: boolean) => void;
};
export const LinksContext = createContext<LinksContextType>({
  idEditedLink: null,
  hasError: false,
  setIdEditedLink: () => null,
  setHasError: () => null,
});

export const useLinksContext = () => useContext(LinksContext);
