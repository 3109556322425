import "@mercdev.com/mercurial-ui/dist/style.css";
import "@mercdev.com/mercurial-ui/src/common/reset.css";
import "@mercdev.com/mercurial-ui/src/common/fonts.css";
import "./index.scss";

import { ThemeProvider } from "@mercdev.com/mercurial-ui";
import ReactDOM from "react-dom/client";
import { UserProvider } from "utils/UserContext";

import { Root } from "./app";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <UserProvider>
    <ThemeProvider>
      <Root />
    </ThemeProvider>
  </UserProvider>
);
