import "./style.scss";

import cn from "classnames";
import { FC, HTMLAttributes } from "react";

import { HeadingItem } from "../HeadingItem/HeadingItem";

type Props = HTMLAttributes<HTMLUListElement>;

const Heading: FC<Props> = ({ className, ...props }) => {
  return (
    <ul className={"link-list-heading"} {...props}>
      <HeadingItem
        className="long-url-column"
        title="Long URL"
        property="url"
      />

      <HeadingItem className="owner-column" title="Owner" property="owner" />

      <HeadingItem
        className="date-added-column"
        title="Date Added"
        property="createdAt"
      />

      <HeadingItem className="views-column" title="Views" property="views" />

      <HeadingItem
        className="short-link-column"
        title="Short Link"
        property="shortUrl"
      />
    </ul>
  );
};

export default Heading;
