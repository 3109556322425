import "react-loading-skeleton/dist/skeleton.css";
import "./App.scss";

import { useState } from "react";

import useFocus from "../../hooks/useFocus";
import CustomDragLayer from "../LinkList/components/CustomDragLayer/CustomDragLayer";
import LinkList from "../LinkList/LinkList";
import { LinksContext } from "../LinkList/useLinksContext";
import { FoldersProvider } from "utils/FoldersContext";
import ShortenerHeader from "../ShortenerHeader/ShortenerHeader";
import { Scrollbar, useTheme } from "@mercdev.com/mercurial-ui";
import Footer from "components/Footer/Footer";

const App = () => {
  const [idEditedLink, setIdEditedLink] = useState("");
  const [hasError, setHasError] = useState(false);

  const { inputUrlRef, setInputUrlFocus } = useFocus();
  useTheme();

  return (
    <div className="app">
      <FoldersProvider>
        <LinksContext.Provider
          value={{ idEditedLink, setIdEditedLink, hasError, setHasError }}
        >
          <header className="app__header">
            <ShortenerHeader
              inputUrlRef={inputUrlRef}
              setInputUrlFocus={setInputUrlFocus}
            />
          </header>
          <Scrollbar>
            <main className="app__content">
              <LinkList setInputUrlFocus={setInputUrlFocus} />

              <CustomDragLayer />
              <Footer />
            </main>
          </Scrollbar>
          <div id="portal-container" />
        </LinksContext.Provider>
      </FoldersProvider>
    </div>
  );
};

export default App;
