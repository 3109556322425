import { apiClient } from "./fetcher";

export interface IFolder {
  id: string;
  name: string;
  orderNumber: number;
  amount: number;
}

/* Folders CRUD */

export const createFolder = (name: string) => {
  return apiClient.post<IFolder>(`/folders`, { name });
};

export const deleteFolder = (id: string, deleteLinks: boolean) => {
  return apiClient.delete(`/folders/${id}`, { data: { deleteLinks } });
};

export const updateFolderOrder = (id: string, orderNumber: number) => {
  return apiClient.post<IFolder[]>(`/folders/update-order/${id}`, {
    orderNumber,
  });
};

export const updateFolderName = (id: string, name: string) => {
  return apiClient.post<IFolder[]>(`/folders/${id}`, { name });
};
