import React, { useState, useEffect } from "react";
import { useLinkStore } from "stores/LinkListStore";
import Heading from "../Heading/Heading";
import Link from "components/Link/Link";
import { DEFAULT_FOLDERS } from "const";
import {
  addFavoriteLink,
  deleteFavoriteLink,
  useLinkList,
  LinkProps,
} from "api/links";
import { IFolder, useFolders } from "utils/FoldersContext";
import { ILink } from "@types";

import EmptyState from "components/EmptyState/EmptyState";
import placeholderImage from "components/EmptyState/images/placeholder.svg";
import { mutateLinksData } from "utils/mutateLinksData";
import { Loader, Pagination } from "@mercdev.com/mercurial-ui";
import { PAGINATION_MENU_ITEMS } from "const";
import SkeletonContent from "components/SkeletonPage/SkeletonContent";

export const LinkTable = ({ itemData }) => {
  const [orderBy] = useLinkStore((state) => [state.orderBy]);
  const [search] = useLinkStore((state) => [state.search, state.setSearch]);
  const [page, setPage] = useLinkStore((state) => [state.page, state.setPage]);
  const [size, setPageSize] = useLinkStore((state) => {
    return [state.size, state.setPageSize];
  });

  const {
    items,
    draggingLinkId,
    setDraggingLinkId,
    folder,
    setInputUrlFocus,
    hasError,
    idEditedLink,
  } = itemData;

  const { mutateFolders } = useFolders();
  const { paginationContext, isLinksLoading, mutateLinks } = useLinkList(
    folder?.id || "",
    orderBy,
    search,
    page,
    size
  );
  const [nextRender, setNextRender] = useState(false);

  useEffect(() => {
    !isLinksLoading && setNextRender(true);
  }, [isLinksLoading]);

  const onAddFavorite = async (
    id: string,
    callback: () => void,
    errorCallback: () => void
  ) => {
    try {
      await addFavoriteLink(id);

      await mutateLinks(async (oldLinks: LinkProps | undefined) => {
        return mutateLinksData(oldLinks, "edit", true, id, "isFavorite");
      }, false);

      await mutateFolders(
        async (oldFolders: IFolder[]) =>
          (oldFolders || []).map((of) => ({
            ...of,
            amount:
              of.name === DEFAULT_FOLDERS.FAVORITES ? of.amount + 1 : of.amount,
          })),
        false
      );

      callback();
    } catch (err) {
      errorCallback();
    }
  };

  const onDeleteFavorite = async (
    id: string,
    callback: () => void,
    errorCallback: () => void
  ) => {
    try {
      await deleteFavoriteLink(id);
      await mutateLinks(async (oldLinks: LinkProps | undefined) => {
        return mutateLinksData(oldLinks, "edit", false, id, "isFavorite");
      }, false);
      if (folder.name === DEFAULT_FOLDERS.FAVORITES) {
        mutateLinks((oldLinks) => {
          return mutateLinksData(oldLinks, "delete", undefined, id);
        }, false);
      }
      await mutateFolders(
        async (oldFolders) =>
          (oldFolders || []).map((of) => ({
            ...of,
            amount:
              of.name === DEFAULT_FOLDERS.FAVORITES ? of.amount - 1 : of.amount,
          })),
        false
      );
      callback();
    } catch (err) {
      errorCallback();
    }
  };

  const handleChangePageSize = (value) => {
    setPage(1);
    setPageSize(value);
  };

  const handleChangePage = (currentPage: number) => {
    setPage(currentPage);
  };

  return (
    <>
      {isLinksLoading && !nextRender && (
        <>
          <div className="link-list__header">
            <Heading className="link-list__item link-list__heading" />
          </div>
          <SkeletonContent />
        </>
      )}
      {isLinksLoading && nextRender ? (
        <>
          <div className="link-list__header">
            <Heading className="link-list__item link-list__heading" />
          </div>
          <div className="list-centering-container with-header">
            <Loader size={48} color="accent" />
          </div>
        </>
      ) : (
        <>
          {folder && items?.length ? (
            <>
              <div className="link-list__header">
                <Heading className="link-list__item link-list__heading" />
              </div>
              <ul className="link-list__table-container">
                {items.map((link: ILink) => (
                  <Link
                    className="link-list__item "
                    key={link.id}
                    link={link}
                    draggingLinkId={draggingLinkId}
                    setDraggingLinkId={setDraggingLinkId}
                    setInputUrlFocus={setInputUrlFocus}
                    onAddFavorite={onAddFavorite}
                    onDeleteFavorite={onDeleteFavorite}
                    isDisabled={hasError && idEditedLink !== link.id}
                  />
                ))}
              </ul>
              <div className="link-list__pagination-container">
                {paginationContext?.pages > 1 && (
                  <div className="link-list__pagination">
                    <Pagination
                      totalPages={paginationContext.pages}
                      currentPage={paginationContext.page}
                      onChange={handleChangePage}
                      gap="normal"
                      size="normal"
                      withPerPage={
                        paginationContext.totalItems >
                        PAGINATION_MENU_ITEMS[0].value
                      }
                      onChangePageSize={(value) => handleChangePageSize(value)}
                      defaultPageSize={paginationContext.size}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="empty-state-container">
              <EmptyState
                image={placeholderImage}
                title="No links here"
                description={
                  search
                    ? `No results matched your search.\nTry again`
                    : "It seems you haven’t added any links yet."
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
