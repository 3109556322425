import "./style.scss";

import {
  Button,
  ButtonGroup,
  CheckBox,
  createToast,
  Heading,
  IconTrash,
  Modal,
  Paragraph,
} from "@mercdev.com/mercurial-ui";
import { FC, useEffect, useState } from "react";

import {
  createFolder,
  deleteFolder,
  IFolder,
  updateFolderOrder,
} from "api/folders";
import { useFolders } from "utils/FoldersContext";
import { useLinkList } from "../../api/links";
import { CONSTANTS } from "const";
import { useLinkStore } from "stores/LinkListStore";

interface IProps {
  show: boolean;
  folder: IFolder;
  onHide: () => void;
  hideFoldersManagementModal: (v: boolean) => void;
}

const FolderDeleteModal: FC<IProps> = ({
  show,
  folder,
  onHide,
  hideFoldersManagementModal,
}) => {
  const [currentFolder, setCurrentFolder] = useLinkStore((state) => [
    state.folder,
    state.setFolder,
  ]);
  const [orderBy] = useLinkStore((state) => [state.orderBy]);
  const [search] = useLinkStore((state) => [state.search, state.setSearch]);
  const [page] = useLinkStore((state) => [state.page]);
  const [size] = useLinkStore((state) => [state.size]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLinksSet, setIsDeleteLinksSet] = useState(false);

  const { folders, mutateFolders } = useFolders();
  const { mutateLinks } = useLinkList(
    currentFolder?.id || "",
    orderBy,
    search,
    page,
    size
  );

  useEffect(() => {
    if (show) {
      hideFoldersManagementModal(true);
      return () => {
        hideFoldersManagementModal(false);
      };
    }
  }, [show]);

  const onUndo = async () => {
    setIsLoading(true);

    try {
      const { data: newFolder } = await createFolder(folder.name);

      const { data: newFolders } = await updateFolderOrder(
        newFolder.id,
        folder.orderNumber
      );

      await mutateFolders(newFolders);
      setIsLoading(false);
    } catch (e) {
      const backendError = e.response.data[0];

      backendError.description
        ? createToast({
            text: backendError.description,
            color: "negative",
          })
        : createToast({
            text: CONSTANTS.GENERAL_ERROR,
            color: "negative",
          });
    }

    setIsLoading(false);
  };

  const onDeleteHandle = async () => {
    setIsLoading(true);

    try {
      await deleteFolder(folder.id, isDeleteLinksSet);

      if (folder.id === currentFolder?.id) setCurrentFolder(folders![0]);

      isDeleteLinksSet
        ? await mutateFolders()
        : await mutateFolders((oldFolders) => {
            return (oldFolders || []).filter((of) => of.id !== folder.id);
          }, false);

      isDeleteLinksSet && (await mutateLinks());

      setIsLoading(false);
      onHide();
      hideFoldersManagementModal(false);

      createToast({
        text: `The folder "${folder.name}" has been deleted!`,
        color: "dark",
        footer: (
          <ButtonGroup gap="small">
            <ButtonGroup.Item>
              <Button variant="secondary" size="small" onClick={onUndo}>
                Undo
              </Button>
            </ButtonGroup.Item>
          </ButtonGroup>
        ),
      });
    } catch (err) {
      setIsLoading(false);

      err.response.data[0].description
        ? createToast({
            text: err.response.data[0].description,
            color: "negative",
          })
        : createToast({
            text: CONSTANTS.GENERAL_ERROR,
            color: "negative",
          });
    }
  };

  const onClose = () => {
    hideFoldersManagementModal(false);
    onHide();
  };

  return (
    <Modal
      className="folder-delete-modal"
      width="560px"
      show={show}
      isShowCloseBtn={!isLoading}
      isOverlayClickable={isLoading ? "static" : true}
      onHide={onClose}
    >
      <Modal.Icon icon={IconTrash} color="negative" />

      <Modal.Title>
        <Heading size={3}>{`Delete the Folder ${folder.name}?`}</Heading>
      </Modal.Title>

      <Modal.Text>
        <Paragraph size={3}>
          By default, deleting the folder will not delete any links inside it.
          You may check the checkbox if you also wish to delete the links within
          the folder.
        </Paragraph>
      </Modal.Text>

      <div className="folder-delete-modal__checkbox-wrapper checkbox-wrapper">
        <CheckBox
          label="I want to delete all links within this folder"
          isChecked={isDeleteLinksSet}
          onChange={setIsDeleteLinksSet}
        />
      </div>

      <Modal.FooterButtons>
        <ButtonGroup direction="vertical">
          <ButtonGroup.Item>
            <Button
              color="negative"
              isLoading={isLoading}
              onClick={onDeleteHandle}
            >
              Delete, I'm sure
            </Button>
          </ButtonGroup.Item>

          <ButtonGroup.Item>
            <Button
              color={"negative"}
              variant="ghost"
              isDisabled={isLoading}
              onClick={onHide}
            >
              Not now
            </Button>
          </ButtonGroup.Item>
        </ButtonGroup>
      </Modal.FooterButtons>
    </Modal>
  );
};

export default FolderDeleteModal;
