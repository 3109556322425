import "./Favicon.scss";

import cn from "classnames";
import { FC, useState } from "react";

import PlaceholderFavicon from "./placeholder-favicon.svg?react";

interface IProps {
  className?: string;
  src: string;
}

const Favicon: FC<IProps> = ({ className, src }) => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  const onLoad = () => {
    setLoading(false);
  };

  const onError = () => {
    setError(true);
  };

  return (
    <>
      {(isLoading || isError) && <PlaceholderFavicon className={className} />}

      <img
        className={cn(className, "favicon", {
          favicon_hidden: isLoading || isError,
        })}
        width="16"
        height="16"
        alt="icon"
        src={src}
        onLoad={onLoad}
        onError={onError}
      />
    </>
  );
};

export default Favicon;
