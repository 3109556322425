import { useUser } from "@mercdev.com/auth";
import { Dummy, Loader } from "@mercdev.com/mercurial-ui";
import "./DummyPage.scss";

const DummyPage = () => {
  const { user, logOut } = useUser();

  return (
    <>
      {user ? (
        <Dummy avatar={user?.avatar} logOut={logOut} />
      ) : (
        <div className="loader-wrapper">
          <Loader size={48} color="accent" />
        </div>
      )}
    </>
  );
};

export default DummyPage;
