import "./style.scss";

import {
  Button,
  createToast,
  Heading,
  IconPlus,
  Modal,
  Paragraph,
} from "@mercdev.com/mercurial-ui";
import { createFolder, IFolder, updateFolderOrder } from "api/folders";
import cn from "classnames";
import EmptyState from "components/EmptyState/EmptyState";
import emptyImage from "components/EmptyState/images/folders.svg";
import Folder from "components/Folder/Folder";
import FolderAction from "components/FolderAction/FolderAction";
import Loader from "components/Loader/Loader";
import { CONSTANTS } from "const";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  OnDragEndResponder,
  OnDragStartResponder,
  OnDragUpdateResponder,
} from "react-beautiful-dnd";
import { useFolders } from "utils/FoldersContext";
import { StrictModeDroppable } from "utils/StrictModeDroppable";

interface IProps {
  show: boolean;
  onHide: () => void;
}

const FoldersManagementModal: FC<IProps> = ({ show, onHide }) => {
  const { folders, isFoldersLoading, isFoldersError, mutateFolders } =
    useFolders();

  const editableFolders = useMemo(
    () => folders?.filter((item) => item.orderNumber >= 2) || [],
    [folders]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isCreationMode, setCreationMode] = useState(false);

  const [placeholderProps, setPlaceholderProps] = useState<{
    clientY: number;
    clientX: number;
    clientHeight: number;
    clientWidth: number;
  }>({
    clientY: 0,
    clientX: 0,
    clientHeight: 0,
    clientWidth: 0,
  });
  const [folderIdToDrug, setFolderIdToDrug] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [newFolder, setNewFolder] = useState<IFolder | null>(null);

  useEffect(() => {
    if (!isFoldersError) return;

    createToast({
      text: CONSTANTS.GENERAL_ERROR,
      color: "negative",
    });
  }, [isFoldersError]);

  useEffect(() => {
    if (isCreationMode || !newFolder) return;

    mutateFolders((oldFolders) => {
      return [...(oldFolders || []), newFolder];
    }, false).then(() => setNewFolder(null));
  }, [isCreationMode, mutateFolders, newFolder]);

  const onAddFolder = async (name: string) => {
    setIsLoading(true);

    try {
      const { data } = await createFolder(name);

      setIsLoading(false);
      setCreationMode(false);
      setNewFolder(data);
    } catch (e) {
      const backendError = e.response.data[0];

      backendError?.description
        ? createToast({
            text: backendError.description,
            color: "negative",
          })
        : createToast({
            text: CONSTANTS.GENERAL_ERROR,
            color: "negative",
          });
    }

    setIsLoading(false);
  };

  const onManagementModalHide = () => {
    setCreationMode(false);

    onHide();
  };

  const getDraggedDom = (draggableId: string) => {
    const queryAttr = "data-rbd-draggable-id";
    const domQuery = `[${queryAttr}='${draggableId}']`;

    return document.querySelector(domQuery);
  };

  const onDrugStart: OnDragStartResponder = ({ draggableId, source }) => {
    setFolderIdToDrug(draggableId);

    const draggedDOM = getDraggedDom(draggableId);
    if (!draggedDOM || !draggedDOM.parentNode) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = source.index;

    const clientY =
      parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
      ) +
      [...draggedDOM.parentNode.children]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
      ),
    });
  };

  const onDrugUpdate: OnDragUpdateResponder = ({
    draggableId,
    source,
    destination,
  }) => {
    if (!destination) return;

    const draggedDOM = getDraggedDom(draggableId);
    if (!draggedDOM || !draggedDOM.parentNode) return;

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = destination.index;
    const sourceIndex = source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];

    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
      ) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
      ),
    });
  };

  const onDragEnd: OnDragEndResponder = async ({
    draggableId,
    destination,
    source,
  }) => {
    setPlaceholderProps({
      clientHeight: 0,
      clientWidth: 0,
      clientX: 0,
      clientY: 0,
    });

    if (!destination || destination.index === source.index) {
      setFolderIdToDrug("");
      return;
    }

    setIsLoading(true);

    try {
      const { data: newFolders } = await updateFolderOrder(
        draggableId,
        destination.index + 2
      );
      setIsLoading(false);
      setFolderIdToDrug("");
      mutateFolders(newFolders);
    } catch (e) {
      const backendError = e.response.data[0];

      backendError?.description
        ? createToast({
            text: backendError.description,
            color: "negative",
          })
        : createToast({
            text: CONSTANTS.GENERAL_ERROR,
            color: "negative",
          });

      setIsLoading(false);
      setFolderIdToDrug("");
    }
  };

  return (
    <Modal
      className={cn(isHidden && "hidden", "folders-modal")}
      width="560px"
      horizontalPadding={0}
      show={show}
      isShowCloseBtn={!isLoading}
      isOverlayClickable={isLoading ? "static" : true}
      onHide={onManagementModalHide}
    >
      <div className="container">
        <Modal.Title>
          <Heading size={2}>Folders Management</Heading>
        </Modal.Title>

        <Modal.Text>
          <Paragraph size={3}>
            Add and remove folders. Change the folders order with drag and drop.
          </Paragraph>
        </Modal.Text>
      </div>
      <section className="folders-modal__folders folders">
        {isFoldersLoading ? (
          <Loader isInContainer size="large" />
        ) : editableFolders.length || isCreationMode ? (
          <DragDropContext
            onDragStart={onDrugStart}
            onDragUpdate={onDrugUpdate}
            onDragEnd={onDragEnd}
          >
            <Heading className="folders__heading" size={5}>
              Your folders
            </Heading>

            <StrictModeDroppable key="folders-list" droppableId="folders-list">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={cn("folders-list")}
                  {...provided.droppableProps}
                >
                  {editableFolders?.map((f, idx) => (
                    <Draggable key={f.id} draggableId={f.id} index={idx}>
                      {(provided, snapshot) => (
                        <Folder
                          className="folders-list__folder"
                          folder={f}
                          isLoading={folderIdToDrug === f.id && isLoading}
                          isDragging={snapshot.isDragging}
                          dndRef={provided.innerRef}
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          hideFoldersManagementModal={setIsHidden}
                        />
                      )}
                    </Draggable>
                  ))}

                  {isCreationMode && (
                    <FolderAction
                      onSubmit={onAddFolder}
                      onCancel={() => setCreationMode(false)}
                    />
                  )}

                  {provided.placeholder}

                  {snapshot.isDraggingOver && (
                    <div
                      className="placeholder"
                      style={{
                        position: "absolute",
                        borderRadius: 12,
                        border: "1px solid rgba(29, 31, 38, 0.2)",
                        top: placeholderProps.clientY,
                        left: placeholderProps.clientX,
                        height: placeholderProps.clientHeight,
                        width: placeholderProps.clientWidth,
                      }}
                    />
                  )}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        ) : (
          <div className="empty-state-container">
            <EmptyState
              className="empty-state"
              isTight
              image={emptyImage}
              title="No folders yet"
              description="Press Add folder to create your first folder"
            />
          </div>
        )}
      </section>

      <div className="add-button-container">
        <Button
          className="add-button"
          variant="third"
          iconName={IconPlus}
          isDisabled={isCreationMode}
          onClick={() => setCreationMode(true)}
        >
          Add Folder
        </Button>
      </div>
    </Modal>
  );
};

export default FoldersManagementModal;
