import { FC, useMemo } from "react";

import Skeleton from "react-loading-skeleton";
import "./SkeletonPage.scss";

const SkeletonContent: FC = () => {
  const skeletonLinks = useMemo(
    () =>
      new Array(10).fill(0).map((_, i) => (
        <li className="link-list__item" key={`link-item-${i}`}>
          <Skeleton height={72} borderRadius={12} width="100%" />
        </li>
      )),
    []
  );

  return <ul className="link-list__table-container">{skeletonLinks}</ul>;
};
export default SkeletonContent;
